import React from 'react';
import { Link } from 'react-router-dom'; 
import RecConsults from '../../../../../assets/images/deepak.png'
import Verifyconsults from '../../../../../assets/images/verify.svg'
import { FaRegClock } from "react-icons/fa";



const ConsultCard = () => {
  return (
    <>
        <div className="dkg-consults-card  mb-5 mt-3 col-xl-10 col-lg-11 card">
            <div className="dkg-card-flexCon">
                <Link to="#" className="dkg-consults-experts-Con">
                    <div className="dkg-experts-first-cols">
                        <div className="dkg-consults-imgCon">
                            <img src={RecConsults} className='dkg-consults-img' alt="RecConsults" />
                        </div>
                    </div>
                </Link>
                <Link to="#" className="dkg-consults-profile-desc">
                    <h2 className="dkg-consultant-name">Deepak Kumar 
                        <span className='dkg-verify-consult'>
                            <img src={Verifyconsults} className='dkg-verify-consult-img' alt="Verify Consults" />
                        </span>
                    </h2>
                    <p className="dkg-consult-job-profile">DK Global Recruitment</p>
                    <div className="dkg-jobs-consult-keyboards">
                        <span className="dkg-jobs-consult-key">Fundraising</span>
                        <span className="dkg-jobs-consult-key">Go To Market Strategy</span>
                        <span className="dkg-jobs-consult-key">Sales</span>
                    </div>
                    <div className="dkg-consults-bioSec">
                        <div className="dkg-consult-bio">
                        It is a long established fact that a reader will be distracted by the readable content of
                         a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                         Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text
                        </div>
                        <Link to="#" className="dkg-readmore-title">Read More...</Link>
                    </div>
                </Link>
                <Link to="#" className="dkg-consults-package-desc">
                    <h4 className="dkg-package-title">Package</h4>
                    <div className="dkg-package-cardcon">
                        <div className="dkg-package-card">
                            <div className="dkg-card-leftcon">
                                <h5 className='dkg-card-left-title'>All Access</h5>
                                <p className='dkg-card-left-subtitle'>
                                <FaRegClock /> 1 Session X 60min
                                </p>
                            </div>
                            <div className="dkg-card-rightcon">
                                <button className='dkg-package-price'> 8000</button>
                            </div>
                        </div>
                        <div className="dkg-package-card">
                            <div className="dkg-card-leftcon">
                                <h5 className='dkg-card-left-title'>All Access</h5>
                                <p className='dkg-card-left-subtitle'>
                                <FaRegClock /> 1 Session X 60min
                                </p>
                            </div>
                            <div className="dkg-card-rightcon">
                                <button className='dkg-package-price'> 8000</button>
                            </div>
                        </div>
                        <div className="dkg-viewplans-btnCon">
                            <button className='dkg-viewplans-btn'>View Plans</button>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    </>
  )
}

export default ConsultCard