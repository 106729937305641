import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData } from "../../services";

// export const updatedataValue = createAsyncThunk(
//     'companyClient/updatekeypair',
//     async ({ id, key, value, clientId, clientname }, thunkAPI) => {
//         const res = await PutData(true, '/jobs-company/relation/' + id, { key, value, clientId, clientname })
//         if (res.status === 200) {
//             return res.payload
//         } else {
//             return thunkAPI.rejectWithValue();
//         }
//     }
// );

// export const allclientscomp = createAsyncThunk(
//     'companyClient/allfetechByIdcompany',
//     async ({ id, clientname }, thunkAPI) => {
//         const res = await GetData(true, '/jobs-company/companyIdRelation/' + id + '/' + clientname)
//         if (res.status === 200) {
//             return res.payload
//         } else {
//             return thunkAPI.rejectWithValue();
//         }
//     }
// )

export const getCategories = createAsyncThunk(
    "clientsproject/frontend/requirnment/categoryDemandlist",
    async (thunkAPI) => {
        const res = await GetData(true, "/category-project-forms");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getProjectdefaultSubCategories = createAsyncThunk(
    "clientsproject/frontend/requirnment/default/subcatlist",
    async ({ keys }, thunkAPI) => {
        const res = await GetData(true, "/subcategory-project-forms/default/" + keys);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getdefaultSubCategories = createAsyncThunk(
    "clientsproject/frontend/requirnment/subcatlist",
    async ({ keys }, thunkAPI) => {
        const res = await GetData(true, "/subcategory-project-forms/default/" + keys);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getProjectSubCategories = createAsyncThunk(
    "clientsproject/frontend/requirnment/projectsubcatlist",
    async ({ categoryId, keys }, thunkAPI) => {
        const res = await GetData(true, "/subcategory-project-forms/" + categoryId + '/' + keys);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getSubCategories = createAsyncThunk(
    "clientsproject/frontend/requirnment/subcatlist",
    async ({ categoryId, keys }, thunkAPI) => {
        const res = await GetData(true, "/subcategory-project-forms/" + categoryId + '/' + keys);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getSubCatDetails = createAsyncThunk(
    "clientsproject/frontend/requirnment/detailsbyid",
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, "/subcategory-project-forms/" + id);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getproject = createAsyncThunk(
    "clientsproject/getprojectlist",
    async ({ jobId, clientId }, thunkAPI) => {
        const res = await GetData(true, "/subcategory-project-forms/project/" + jobId + "/" + clientId);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getroletype = createAsyncThunk(
    "clientsproject/roletyplist",
    async ({ jobId, clientId }, thunkAPI) => {
        const res = await GetData(true, "/subcategory-client-forms/role/" + jobId + "/" + clientId);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const usersSlice = createSlice({
    name: "clientsproject",
    initialState: {
        activeroleList: [],
        activeprojecList: [],
        categoryReqList: [],
        subCategoryReqList: [],
        subCategoryDetails: [],
        isSuccess: false,
        isTabActive: false,
        isCatInsert: false,
        isSubCatInsert: false,
        isCatUpdate: false,
        isSubCatUpdate: false,
        isLoading: false,
        issLoading: false,
        isSubLoading: false,
        isSubReoder: false,
        isError: false,
        isDelete: false,
        issDelete: false,
        isReoder: false,
        isProjectLoading: false,
    },
    reducers: {
        clearState: (state) => {
            state.isProjectLoading = false;
            state.isSubReoder = false;
            state.isDetails = false;
            state.isSubLoading = false;
            state.issLoading = false;
            state.isLoading = false;
            state.isSubCatInsert = false;
            state.isSuccess = false;
            state.isTabActive = false;
            state.isError = false;
            state.isCatInsert = false;
            state.isSubCatUpdate = false;
            state.isCatUpdate = false;
            state.isDelete = false;
            state.issDelete = false;
            state.isReoder = false;
        },
    },
    extraReducers: {

        [getSubCatDetails.pending]: (state) => {
            state.isError = false;
            state.isDetails = false;
        },
        [getSubCatDetails.fulfilled]: (state, { payload }) => {
            state.isDetails = true;
            state.subCategoryDetails = payload.result;
        },
        [getSubCatDetails.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isDetails = false;
        },

        [getdefaultSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isTabActive = false;
        },
        [getdefaultSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isTabActive = true;
            state.isSuccess = true;
            state.subCategoryReqList = payload.result;
        },
        [getdefaultSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isTabActive = false;
        },
        [getProjectdefaultSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isTabActive = false;
        },
        [getProjectdefaultSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isTabActive = true;
            state.isSuccess = true;
            state.subCategoryReqList = payload.result;
        },
        [getProjectdefaultSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isTabActive = false;
        },

        [getProjectSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isTabActive = false;
        },
        [getProjectSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isTabActive = true;
            state.isSuccess = true;
            state.subCategoryReqList = payload.result;
        },
        [getProjectSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isTabActive = false;
        },
        [getSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isTabActive = false;
        },
        [getSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isTabActive = true;
            state.isSuccess = true;
            state.subCategoryReqList = payload.result;
        },
        [getSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isTabActive = false;
        },

        [getCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.categoryReqList = payload.result;
        },
        [getCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getroletype.pending]: (state) => {
            state.isProjectLoading = true;
            state.isError = false;
        },
        [getroletype.fulfilled]: (state, { payload }) => {
            state.isProjectLoading = false;
            state.isSuccess = true;
            state.activeroleList = payload.result;
        },
        [getroletype.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isProjectLoading = false;
            state.isSuccess = false;
        },
        [getproject.pending]: (state) => {
            state.isProjectLoading = true;
            state.isError = false;
        },
        [getproject.fulfilled]: (state, { payload }) => {
            state.isProjectLoading = false;
            state.isSuccess = true;
            state.activeprojecList = payload.result;
        },
        [getproject.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isProjectLoading = false;
            state.isSuccess = false;
        },
    },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
