import React,{useState , useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FaInfo } from "react-icons/fa"; 
import {getCMS} from "../../../../../slice/cmsSlice";
import SubmitButton from "../../../../ui/submitButton";


const ProfileSummaryTab = () => {
    const dispatch = useDispatch();
    const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
    const [profileSummary, setProfileSummary] = useState("");

    useEffect(() => {
        dispatch(getCMS({}));
      }, [dispatch]);
    
      useEffect(() => {
        if (!isSuccess && cmsList.length > 0) {
          setProfileSummary(cmsList[36].description);
        }
    }, [isSuccess]);
  return (
    <>
       <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">Profile Summary</h1>
            <span className="dkg-desired-info d-none">
            <FaInfo />
            </span>
        </div>
        <div className="dkg-desired-job-mainCon dkg-cv-preview-main dkg-cv-profile-summ-main">
            <div className="dkg-desired-textarea-Con">
            <div className="form-control dkg-desired-textarea">
                {profileSummary}
            </div>
            </div>
            <div className="dkg-desired-center-sec dkg-desired-job-centersec dkg-profile-summCon">
            {/* <MessageBox
                                value={
                                    coverLetter != ""
                                    ? details[11].otherProjectOverview
                                    : ""
                                }
                                onChange={(value) => setCoverLetter(value)}
                            /> */}
            <textarea className="dkg-createcv-textarea dkg-profile-textareCon" placeholder="Write here..."></textarea>
            </div>
            <div className="dkg-desired-save-btnCon">
            <SubmitButton
                txt="Save"
                className="dkg-desired-save-btn btn"
            />
            </div>
        </div>
    </>
  )
}

export default ProfileSummaryTab