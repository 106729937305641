import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Facebook from "../../../../../../assets/images/facebook.png";
import Linkedin from "../../../../../../assets/images/linkedin.png";
import Instagram from "../../../../../../assets/images/instagram.png";
import Youtube from "../../../../../../assets/images/youtube.png";
import TwitterLogo from "../../../../../../assets/images/twitter-new.png";
import SubmitButton from "../../../../../ui/submitButton";
// import { updateCompanyJobSpec } from '../../../../slice/cmsSlice'
import "./socail-media.scss";

const MediaTab = ({ companySocialMedia }) => {
  const dispatch = useDispatch();
  const [fbUrl, setfbUrl] = useState("");
  const [linkdinUrl, setlinkdinUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");

  const [rowId, setRowId] = useState();

  const SubmitBtn = (data) => {
    switch (
      data
      // case "fbUrl":
      //   dispatch(
      //     updateCompanyJobSpec({
      //       id: rowId,
      //       key: "fbUrl",
      //       value: fbUrl,
      //     })
      //   );
      //   break;
      // case "linkdinUrl":
      //   dispatch(
      //     updateCompanyJobSpec({
      //       id: rowId,
      //       key: "linkdinUrl",
      //       value: linkdinUrl,
      //     })
      //   );
      //   break;
      // case "instagramUrl":
      //   dispatch(
      //     updateCompanyJobSpec({
      //       id: rowId,
      //       key: "instagramUrl",
      //       value: instaUrl,
      //     })
      //   );
      //   break;
      // case "youtubeUrl":
      //   dispatch(
      //     updateCompanyJobSpec({
      //       id: rowId,
      //       key: "youtubeUrl",
      //       value: youtubeUrl,
      //     })
      //   );
      //   break;
      // case "twitterUrl":
      //   dispatch(
      //     updateCompanyJobSpec({
      //       id: rowId,
      //       key: "twitterUrl",
      //       value: twitterUrl,
      //     })
      //   );
      //   break;
    ) {
    }
  };

  const openUrl = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <>
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">Media</div>
      </div>
      <div className="dkg-company-sm-mainCon">
        <div className="dkg-company-sm-Con">
          <div className="dkg-pagetype-mainRow row">
            <div className="col-12 dkg-pagetype-mainCol">
              <div className="dkg-social-box-cols">
                <div className="dkg-social-media-box">
                  <div className="dkg-socail-media-icon">
                    <div className="dkg-company-sm-imgcon">
                      <img
                        src={Facebook}
                        alt="Facebook"
                        className="dkg-company-sm-img"
                      />
                    </div>
                  </div>
                  <div className="dkg-company-sm-nameurl-con">
                    <div className="dkg-timeflex">
                      <small className="dkg-social-media-name">
                        {companySocialMedia.length > 0
                          ? companySocialMedia[0].fbUrl
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="dkg-send-urlbtn-con">
                    <div className="dkg-send-urlbtn-flex">
                      <small className="dkg-social-media-name-btn">
                        <SubmitButton
                          onClick={() => openUrl(companySocialMedia[0].fbUrl)}
                          txt="View"
                          className="dkg-send-urlbtn btn"
                        />
                      </small>
                    </div>
                  </div>
                </div>
                <div className="dkg-social-media-box">
                  <div className="dkg-socail-media-icon">
                    <div className="dkg-company-sm-imgcon">
                      <img
                        src={Linkedin}
                        alt="Linkedin"
                        className="dkg-company-sm-img"
                      />
                    </div>
                  </div>
                  <div className="dkg-company-sm-nameurl-con">
                    <div className="dkg-timeflex">
                      <small className="dkg-social-media-name">
                        {companySocialMedia.length > 0
                          ? companySocialMedia[0].linkdinUrl
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="dkg-send-urlbtn-con">
                    <div className="dkg-send-urlbtn-flex">
                      <small className="dkg-social-media-name-btn">
                        <SubmitButton
                          onClick={() =>
                            openUrl(companySocialMedia[0].linkdinUrl)
                          }
                          txt="View"
                          className="dkg-send-urlbtn btn"
                        />
                      </small>
                    </div>
                  </div>
                </div>
                <div className="dkg-social-media-box">
                  <div className="dkg-socail-media-icon">
                    <div className="dkg-company-sm-imgcon">
                      <img
                        src={Instagram}
                        alt="Youtube"
                        className="dkg-company-sm-img"
                      />
                    </div>
                  </div>
                  <div className="dkg-company-sm-nameurl-con">
                    <div className="dkg-timeflex">
                      <small className="dkg-social-media-name">
                        {companySocialMedia.length > 0
                          ? companySocialMedia[0].instagramUrl
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="dkg-send-urlbtn-con">
                    <div className="dkg-send-urlbtn-flex">
                      <small className="dkg-social-media-name-btn">
                        <SubmitButton
                          onClick={() =>
                            openUrl(companySocialMedia[0].instagramUrl)
                          }
                          txt="View"
                          className="dkg-send-urlbtn btn"
                        />
                      </small>
                    </div>
                  </div>
                </div>
                <div className="dkg-social-media-box">
                  <div className="dkg-socail-media-icon">
                    <div className="dkg-company-sm-imgcon">
                      <img
                        src={Youtube}
                        alt="Youtube"
                        className="dkg-company-sm-img"
                      />
                    </div>
                  </div>
                  <div className="dkg-company-sm-nameurl-con">
                    <div className="dkg-timeflex">
                      <small className="dkg-social-media-name">
                        {companySocialMedia.length > 0
                          ? companySocialMedia[0].youtubeUrl
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="dkg-send-urlbtn-con">
                    <div className="dkg-send-urlbtn-flex">
                      <small className="dkg-social-media-name-btn">
                        <SubmitButton
                          onClick={() =>
                            openUrl(companySocialMedia[0].youtubeUrl)
                          }
                          txt="View"
                          className="dkg-send-urlbtn btn"
                        />
                      </small>
                    </div>
                  </div>
                </div>
                <div className="dkg-social-media-box">
                  <div className="dkg-socail-media-icon">
                    <div className="dkg-company-sm-imgcon">
                      <img
                        src={TwitterLogo}
                        alt="DK Global Website"
                        className="dkg-company-sm-img"
                      />
                    </div>
                  </div>
                  <div className="dkg-company-sm-nameurl-con">
                    <div className="dkg-timeflex">
                      <small className="dkg-social-media-name">
                        {companySocialMedia.length > 0
                          ? companySocialMedia[0].twitterUrl
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="dkg-send-urlbtn-con">
                    <div className="dkg-send-urlbtn-flex">
                      <small className="dkg-social-media-name-btn">
                        <SubmitButton
                          onClick={() =>
                            openUrl(companySocialMedia[0].twitterUrl)
                          }
                          txt="View"
                          className="dkg-send-urlbtn btn"
                        />
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaTab;
