import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
// import './checkbox.scss';
import $ from "jquery";
import { deleteInternalMail, updateInternalMailStatus } from "../../../../slice/mailbox/mailboxSlice";
import { showError } from "../../../utils/messages/messageSlice";
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },
    },
}));
const CheckboxPopup = (props) => {
    const dispatch = useDispatch();
    
    const [showDelete, setShowDelete] = useState(false)
    const [isActive, setIsActive] = useState(false);
    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')


    useEffect(() => {
        toggleCheckbox()
    },[props.checkedValue])


    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setTimeout(() => {
            setIsActive(false);
        }, 100);
    };

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
        }
    }
    const deleteSelected = () => {
        setShowDelete(true)
    }
    const toggleCheckbox = (mail_id) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            setIsActive(true)
        } else {
            setCountCheck(0)
            setIsActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }
    const handleClose = () => {
        setShowDelete(false)
    }

    const updateStatus = (status) => {
        const checkedValues = [];
        document.querySelectorAll('input[name="chkCandidate[]"]:checked').forEach((checkbox) => {
            checkedValues.push(checkbox.value)
        });
        if(checkedValues.length > 0) {
            const data = {
              mail_ids: checkedValues,
              updated_status: status,
            };
            dispatch(updateInternalMailStatus(data));
        }else{
            dispatch(showError({msg: 'Please select a mail'}));
        }
        handleToggle();
      };

      const deleteMail = () => {
        const checkedValues = [];
        document.querySelectorAll('input[name="chkCandidate[]"]:checked').forEach((checkbox) => {
            checkedValues.push(checkbox.value)
        });
        if(checkedValues.length > 0) {
            const data = {
              mail_ids: checkedValues,
            };
            dispatch(deleteInternalMail(data))
        }else{
            dispatch(showError({msg: 'Please select a mail'}));
        }
        handleToggle();
      }
    return (
        <React.Fragment>
            {/* <div className="dk-smsTableCheckbox">
                <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={props.mail_id} onChange={(e) => toggleCheckbox()} />
            </div> */}
             <div className={`dk-smsMessage-selectMsg dkg-mailbox-slectMesage ${isActive ? "activePopup" : ""}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-selectNo">{countCheck}</div>
                    <div className="dk-rowSelected" style={{minWidth: "180px" , maxWidth: "180px"}}>
                        <span>Emails Selected</span>
                    </div>
                    <div className="dk-checkAll" onClick={toggleCheckAll} style={{minWidth: "130px"}}>
                        <span><i className="fas fa-check"></i></span>
                        {checkBtnTxt}
                    </div>
                    {props.email.is_read == true ?
                    <div className="dk-checkAll" onClick={() => updateStatus("unread")} style={{minWidth: "130px"}}>
                        <span><i className="fa fa-folder"></i></span>
                        Make Unread
                    </div>
                    : ''}
                    {props.type == 'Inbox' ? 
                    <div className="dk-checkAll" onClick={() => updateStatus("categorzied")} style={{minWidth: "130px"}}>
                        <span><i className="fa fa-bars"></i></span>
                        Move to Category
                    </div>
                    : ''}
                    {props.type == 'Inbox' ? 
                    <div className="dk-checkAll" onClick={() => updateStatus("Archive")} style={{minWidth: "140px"}}>
                        <span><i className="fas fa-archive"></i></span>
                        Move to Archive
                    </div>
                    : ''}
                    {props.type == 'Archive' ? 
                    <div className="dk-checkAll" onClick={() => updateStatus('Inbox')} style={{minWidth: "140px"}}>
                        <span><i className="fas fa-archive"></i></span>
                        Move to Unarchive
                    </div>
                    : ''}

                    {props.type != 'Trash' ? 
                    <div className="dk-checkAll" onClick={() => updateStatus("Trash")} style={{minWidth: "140px"}}>
                        <span><i className="far fa-trash-alt"></i></span>
                        Move to Delete
                    </div>
                    : ''}

                    {props.type == 'Trash' ? 
                    <div className="dk-checkAll" onClick={() => deleteMail()} style={{minWidth: "140px"}}>
                        <span><i className="far fa-trash-alt"></i></span>
                        Delete Permanently
                    </div>
                    : ''}
                    <div className="dk-checkAll" onClick={handleToggle} style={{minWidth: "80px" , maxWidth: "80px" , backgroundColor:"#f2f2f2" , 
                    borderTopRightRadius: "5px" , borderBottomRightRadius: "5px"}}>
                        <span><i className="fas fa-times"></i></span>
                        Cancel
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CheckboxPopup;