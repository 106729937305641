import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultJobsRole,
  updateRoleValue,
} from "../../../../../../slice/jobSpec/jobSpecSlice";

const RoletypeTab = ({ jobId, clientid }) => {
  const dispatch = useDispatch();
  const { isLoading, roletypeRelationData, roletypeList, isSuccess } =
    useSelector((state) => state.jobsSpec);

  const { activeroleList, isProjectLoading } = useSelector(
    (state) => state.clientproject
  );
  const [selectedId, setSelectedId] = useState(null);
  const [clist, setclist] = useState([]);

  useEffect(() => {
    if (!isProjectLoading && activeroleList) {
      setSelectedId(activeroleList.companyId);
    }
  }, [isProjectLoading]);

  useEffect(() => {
    if (!isLoading && roletypeRelationData && roletypeRelationData.length > 0) {
      setclist(roletypeRelationData);
    }
  }, [isLoading]);

  const handleChange = (id, companyname) => {
    setSelectedId(id);
    dispatch(
      updateRoleValue({
        id: id,
        key: "isShowInJob",
        value: 1,
        jobsId: jobId,
        clientId: clientid,
        clientname: companyname,
        tableName: "jobs_relationship_roletypes",
      })
    );
  };

  return (
    <>
      <div className="dkg-company-list-mainCon">
        <div className="dkg-company-list-tableCon table-responsive">
          <table className="dkg-company-list-table table table-borderded">
            <thead>
              <tr>
                <th>Role Type</th>
                <th>Linked</th>
              </tr>
            </thead>
            <tbody>
              {clist.map((details, index) => (
                <tr key={index + 1}>
                  <td>{details.categoryName}</td>
                  <td>
                    <span className="dkg-clientinfo-checkbox">
                      <input
                        type="radio"
                        className="form-control"
                        name="clientinfo-checkbox-project"
                        //checked={details.isShowInJob === selectedItem}
                        data-id={details.id}
                        data-companyname={details.categoryName}
                        //onChange={handleCheckboxChange}
                        id={details.id}
                        checked={selectedId === details.id}
                        onChange={() =>
                          handleChange(details.id, details.categoryName)
                        }
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RoletypeTab;
