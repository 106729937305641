import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./myacc-navbar.scss";
import "./mobile-navbar.css";
import { FaCaretDown ,FaGift, FaPodcast , FaUserPlus ,FaCog , FaWrench ,FaRegUser ,FaSearchengin , FaChevronDown, FaEuroSign } from "react-icons/fa";
import Submenu from "./submenu/MyaccSubmenu";
import { Navbar,Card,Accordion} from "react-bootstrap";
import { jobList } from "../../../../../slice/jobs/jobsSlice";
import Logo from '../../../../../assets/images/dkgcrmlogo.png'
import Whatsapps from "../../../../../assets/images/whatsapps.png";
import JobseekerNav from '../../userNav/JobseekerNav' 
import { RxDashboard ,RxEnvelopeClosed  } from "react-icons/rx";
import { RiBriefcase4Line  , RiArrowLeftLine} from "react-icons/ri";
import { FaSignOutAlt , FaArrowLeft } from "react-icons/fa";
import $ from 'jquery';
import  { logoutUser, getCandetails } from '../../../../../slice/auth/authSlice';

const MyAccountNav = (props) => {
  const dispatch = useDispatch();
  const { isLoading, jobseDataList } = useSelector((state) => state.jobs);
  const [data, setData] = useState([]);

  const pathname = window.location.pathname;

  const { userInfo, candidateUserDetails } = useSelector((state) => state.auth);
  const { internalMailsCount } = useSelector((state) => state.mailbox);
  const segment1 = pathname.split("/")[1];
  const segment2 = pathname.split("/")[2];
  // const segment1 = pathname.split("/")[1];
  // const segment3 = pathname.split("/")[3];
  //console.log(userInfo);
  const logout = async () => {
      dispatch(logoutUser({}))
  }
  useEffect(() => {
    document.title =
      pathname.split("/")["1"].charAt(0).toUpperCase() +
      pathname.split("/")["1"].slice(1) +
      " | DK MasterBox";

    dispatch(jobList({ isDelete: 1 }));
  }, [pathname]);

  useEffect(() => {
    if (!isLoading && jobseDataList.length > 0) {
      setTimeout(() => {
        setData(jobseDataList);
      }, 200);
    }
  }, [isLoading]);
  // const navbarMenu = document.getElementById("dkg-candmo-navbar");
  // const burgerMenu = document.getElementById("dkg-cand-mo-burger");
  // const overlayMenu = document.querySelector(".dkg-cand-mo-overlay");
  const [isOpen, setIsOpen] = useState(false);
  const openButtonRef = useRef();
  const [isChevronDown, setIsChevronDown] = useState(false);
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const menuRef = useRef();
  const serviceRef = useRef();
  const myprofileRef = useRef();
  const settingRef = useRef();

  useEffect(() => {
     const handler = (e) =>{
       
       if(!menuRef.current.contains(e.target)){
        setIsOpen(false)
       }
      //  if(!serviceRef.current.contains(e.target)){
      //   setIsChevronDown(false);
      //   ; 
      //  } 
      //  if(!myprofileRef.current.contains(e.target)){
      //   setIsMyProfile(false)
      //  }
      //  if(!settingRef.current.contains(e.target)){
      //   setIsSettings(false)
      //  }
     }
     document.addEventListener("mousedown" , handler);
             
     return () =>{
        document.removeEventListener("mousedown" , handler);
     }
  })
  
  const chevronUpDown = () =>{
   setIsChevronDown(!isChevronDown);
  };

  const showprofileToggle = () =>{
    setIsMyProfile(!isMyProfile);
  }
  const showsettingToggle = () =>{
    setIsSettings(!isSettings);
  }
//   const  dropdownLinks = document.querySelectorAll(".dkg-cand-mo-acc-submenu .dkg-cand-submenu-item");
//   const  activesubManu = document.querySelector(".dkg-cand-mo-acc-submenu");
// dropdownLinks.forEach(function(link) {
//    link.addEventListener("click", function(event) {
//       activesubManu.classList.add("active")

//    });
// });
const handleExternalLink = () => {
  window.location.href = 'https://wa.me/353830849152?text=Hi DK Global Recruitment';
}
$(document).ready(function(){
  $('.dkg-cand-mo-acc-submenu .dkg-cand-submenu-item .dkg-cand-mo-acc-submenu-link').click(function(){
    $('.dkg-cand-submenu-item .dkg-cand-mo-acc-submenu-link').removeClass("grey-light-active");
    $(this).addClass("grey-light-active");
});
});

const firstUrl = segment1.toLocaleUpperCase();

const removeFirstMinusSign = firstUrl.replace("-", " ");
// const secondUrl = segment2.toLocaleUpperCase();
 const secondUrl = "- ".concat(segment2);
 const removeSecondMinusSign = secondUrl.replace("-", " ");

  const  addMinuesSign = removeSecondMinusSign.replace("-", " ");



  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="dkg-myacc-navbar">
        <div className="Lsidebar">
          <div className="d-flex dflex2">
            <div className="profile-info order-md-first">
              <Navbar.Brand href="/dashboard">
                <figure>
                  <img
                    src={
                      userInfo
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          userInfo.profileImage
                        : ""
                    }
                    className="img-fluid img-circle"
                    alt=""
                  />
                </figure>
              </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="dkg-mynavbar-tabNav"
            >
              <ul className="dkg-mynavbar-tabNav-ul">
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/dashboard"
                    className={`${
                      segment1 === "dashboard" ? "grey-active" : ""
                    }`}
                  >
                    Dashboard 
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/mailbox"
                    className={`${segment1 === "mailbox" ? "grey-active" : ""}`}
                  >
                    Mailbox{" "}
                    {internalMailsCount?.inbox > 0 ? (
                      <span className="dkg-taskbadgecon">
                        {" "}
                        {internalMailsCount?.inbox}{" "}
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/open-jobs"
                    className={`${
                      segment1 === "open-jobs" ? "grey-active" : ""
                    }`}
                  >
                    Open Jobs
                    {data.length > 0 ? (
                      <span className="dkg-taskbadgecon">{data.length}</span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/applied"
                    className={`${segment1 === "applied" ? "grey-active" : ""}`}
                  >
                    Applied
                    {/* <span className="dkg-taskbadgecon">0</span> */}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/interviews"
                    className={`${
                      segment1 === "interviews" ? "grey-active" : ""
                    }`}
                  >
                    Interviews
                    {/* <span className="dkg-taskbadgecon">0</span> */}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/offered"
                    className={`${
                      segment1 === "offered" ? "grey-active" : ""
                    }`}
                  >
                    Offered
                    {/* <span className="dkg-taskbadgecon">0</span> */}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/referrals"
                    className={`${segment1 === "referrals" ? "grey-active" : ""}`}
                  >
                    Referrals
                    {/* <span className="dkg-taskbadgecon">0</span> */}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li dkg-services-li">
                  <Link
                    to="/services/home"
                    className={`${
                      segment1 === "services" ? "white-active" : ""
                    }`}
                  >
                    Services
                    {/* <span className="dkg-taskbadgecon">12</span> */}
                    <span className="dkg-dropdown-toggle ml-2">
                      <FaCaretDown />
                    </span>
                  </Link>
                  <Submenu path={pathname} />
                </li>
                <li className="dkg-myacc-nav-main-li dkg-profile-li">
                  <Link
                    to="/my-profile/summary"
                    className={`${
                      segment1 === "my-profile" ? "white-active" : ""
                    }`}
                  >
                    My Profile
                    <span className="dkg-dropdown-toggle ml-2">
                      <FaCaretDown />
                    </span>
                  </Link>
                  <Submenu path={pathname} />
                </li>
                <li className="dkg-myacc-nav-main-li dkg-setting-li">
                  <Link
                    to="/settings/faq"
                    className={`${
                      segment1 === "settings" ? "white-active" : ""
                    }`}
                  >
                    Setting
                    <span className="dkg-dropdown-toggle ml-2">
                      <FaCaretDown />
                    </span>
                  </Link>
                  <Submenu path={pathname} />
                </li>
              </ul>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
      <div className="dkg-candmo-header">
        <section className="dkg-candmo-wrapper dkg-candmo-container">
            <div className="dkg-cand-mo-burger"  onClick={toggleMenu} id="dkg-cand-mo-burger">
                {/* <span className="dkg-cand-mo-burger-line"></span>
                <span className="dkg-cand-mo-burger-line"></span>
                <span className="dkg-cand-mo-burger-line"></span> */}
                <i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
            </div>
            {/* <Link to="./dashboard" className="dkg-candmo-brand">
              <img src={Logo} className="img-fluid" alt="DKG CRM Logo"  />
           </Link> */}
           <div className="dkg-cand-headerTitle" style={{ color: props.textcolor }}>
              {/* <Link to={props.dashLink} style={{}} className="mr-2">
                  {firstUrl}  
              </Link>
              <Link to={props.dashboardLink} style={{ color: props.linkcolor }} className="mr-2">
                  {
                    segment2 === "undefined" ? " " : `- ${segment2}`
                  }
              </Link> */}
              {
                firstUrl != " " && secondUrl === "- undefined" ? `${removeFirstMinusSign}` : <>
                 <Link to='#' style={{}} className="mr-2 dkg-main-title">
                  {removeFirstMinusSign}  
                  </Link>
                  <Link to="#" style={{ color: props.linkcolor }} className="mr-2  dkg-submenu-title">{`- ${addMinuesSign}`}</Link>
                </>
              }
            </div>
            {/* <div className="dkg-myacc-userinfo">
              <JobseekerNav />
            </div> */}
            <div className="dkg-whatsapp-icon-con">
              <Link to="#" target='_blank' className="whatsapp-icon-link" onClick={handleExternalLink}>
                <img src={Whatsapps} className='dkg-whatsapp-icon' alt='Whatsapp-1' />
            </Link>
            </div>
           <span className={isOpen ? "dkg-cand-mo-overlay active" : "dkg-cand-mo-overlay"} ></span>
           <nav ref={menuRef} className={isOpen ? "dkg-candmo-navbar active" : "dkg-candmo-navbar"}>
                <ul className="dkg-cand-mo-menu" id="dkg-cand-mo-menu" style={{position: "relative"}}>
                    <div className="dkg-top-close-tab">
                      <Link to="#" className="dkg-close-popup-icon" onClick={toggleMenu}> <span className="dkg-left-arrowicon"><FaArrowLeft  /></span>Close Menu</Link>
                      <Link to="#" className="dkg-close-popup-icon" onClick={logout}> <span className="dkg-left-arrowicon"><FaSignOutAlt  /></span>Signout</Link>
                    </div>
                    <div className="dkg-company-corss-logoCon">
                      {/* <div className="dkg-cross-icon">
                        <i onClick={toggleMenu} className="fas fa-times"></i>
                      </div> */}
                      {/* <div className="dkg-company-logoCon">
                        <Link to="./dashboard" className="dkg-candmo-brand">
                          <img src={Logo} className="img-fluid dkg-candmo-brand-img" alt="DKG CRM Logo"  />
                        </Link>
                      </div> */}
                    </div>
                    <div className="dkg-myacc-telegram">
                      <div className="dkg-myacc-mo-userinfo">
                        {/* <JobseekerNav /> */}
                        <div className="dkg-user-account-infoCon">
                          <div id="dropdown-basic" className="dk-user-account-imgCon">
                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} className="img-fluid dkg-candidate-img" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="dkg-myacc-usertitle">
                         <h5 className="dkg-candidate-name">{`${userInfo.firstName} ${userInfo.lastName}`}</h5>
                         <p className="dkg-candiate-email">{userInfo.email}</p>
                      </div>
                    </div>
                    <li className="dkg-cand-mo-menu-item">
                      <Link to="/dashboard" className={`${segment1 === "dashboard" ? "dkg-cand-mo-menu-link grey-active" : "dkg-cand-mo-menu-link"}`}>
                        <span className="dkg-mo-sidemenu-icon"><RxDashboard /></span>
                        Dashboard</Link>
                    </li>
                    <li className="dkg-cand-mo-menu-item">
                      <Link to="/mailbox" className={`${segment1 === "mailbox" ? "dkg-cand-mo-menu-link grey-active" : "dkg-cand-mo-menu-link"}`}>
                      <span className="dkg-mo-sidemenu-icon"><RxEnvelopeClosed /></span>
                        Mailbox</Link>
                    </li>
                    <li className="dkg-cand-mo-menu-item">
                      <Link to="/open-jobs" className={`${segment1 === "open-jobs" ? "dkg-cand-mo-menu-link grey-active" : "dkg-cand-mo-menu-link"}`}>
                        <span className="dkg-mo-sidemenu-icon"><FaSearchengin  /></span>
                        Open Jobs</Link>
                    </li>
                    <li className="dkg-cand-mo-menu-item">
                      <Link to="/applied" className={`${segment1 === "applied" ? "dkg-cand-mo-menu-link grey-active" : "dkg-cand-mo-menu-link"}`}>
                        <span className="dkg-mo-sidemenu-icon"><RiBriefcase4Line /></span>
                        Applied</Link>
                    </li>
                    <li className="dkg-cand-mo-menu-item">
                      <Link to="/interviews" className={`${segment1 === "interviews" ? "dkg-cand-mo-menu-link grey-active" : "dkg-cand-mo-menu-link"}`}>
                        <span className="dkg-mo-sidemenu-icon"><FaPodcast /></span>
                        Interviews</Link>
                    </li>
                    <li className="dkg-cand-mo-menu-item">
                      <Link to="/offered" className={`${segment1 === "offered" ? "dkg-cand-mo-menu-link grey-active" : "dkg-cand-mo-menu-link"}`}>
                      <span className="dkg-mo-sidemenu-icon"><FaGift /></span>
                        Offered</Link>
                    </li>
                    <li className="dkg-cand-mo-menu-item">
                      <Link to="/referrals" className={`${segment1 === "referrals" ? "dkg-cand-mo-menu-link grey-active" : "dkg-cand-mo-menu-link"}`}>
                      <span className="dkg-mo-sidemenu-icon"><FaUserPlus /></span>
                        Referrals</Link>
                    </li>
                    <Accordion className="dkg-cand-mo-menu-item dkg-cand-mo-menu-acc-item" defaultActiveKey="">
                      <Card className="dkg-cand-mo-menu-acc-Card"> 
                        <Accordion.Toggle className={isChevronDown ? "dkg-cand-mo-menu-acc-toggle active" : "dkg-cand-mo-menu-acc-toggle"} onClick={chevronUpDown} as={Card.Header} eventKey="0">
                          <Link to="/services/home" data-toggle="dkg-cand-mo-submenu" className={`${segment1 === "services" ? "dkg-cand-mo-menu-link dkg-chevronupdown-link grey-active" : "dkg-cand-mo-menu-link dkg-chevronupdown-link"}`}>
                            <span className="dkg-mo-sidemenu-icon"><FaEuroSign /></span>
                            Services<i className={isChevronDown ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
                          </Link>
                        </Accordion.Toggle>
                        <Accordion.Collapse className="dkg-cand-mo-menu-acc-collapse" eventKey="0">
                          <Card.Body className="dkg-cand-menu-acc-cardbody">
                            <ul className="dkg-cand-mo-acc-submenu">
                              <li className="dkg-cand-submenu-item">
                                <Link 
                                  to="/services/home" className={`${segment2 === "home" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Home
                                </Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/services/services" className={`${segment2 === "services" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Services</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/services/consultants" className={`${segment2 === "consultants" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Consultants</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/services/pricing" className={`${segment2 === "pricing" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Pricing</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/services/testimonial" className={`${segment2 === "testimonial" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Testimonial</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/services/contact-us" className={`${segment2 === "contact-us" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Contact us</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/services/legal" className={`${segment2 === "legal" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Legal</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/services/faq-s" className={`${segment2 === "faq-s" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>FAQ's</Link>
                              </li>
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="dkg-cand-mo-menu-acc-Card"> 
                        <Accordion.Toggle className={isMyProfile ? "dkg-cand-mo-menu-acc-toggle active" : "dkg-cand-mo-menu-acc-toggle"} onClick={showprofileToggle} as={Card.Header} eventKey="1">
                          <Link to="/my-profile/summary"  data-toggle="dkg-cand-mo-submenu" className={`${segment1 === "my-profile" ? "dkg-cand-mo-menu-link dkg-chevronupdown-link grey-active" : "dkg-cand-mo-menu-link dkg-chevronupdown-link"}`}>
                            <span className="dkg-mo-sidemenu-icon"><FaRegUser/></span>
                             My Profile <i className={isMyProfile ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
                          </Link>
                        </Accordion.Toggle>
                        <Accordion.Collapse className="dkg-cand-mo-menu-acc-collapse" eventKey="1">
                          <Card.Body className="dkg-cand-menu-acc-cardbody">
                            <ul className="dkg-cand-mo-acc-submenu">
                              <li className="dkg-cand-submenu-item">
                                <Link to="/my-profile/summary" className={`${segment2 === "summary" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Summary</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/my-profile/my-account" className={`${segment2 === "my-account" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>My Account</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/my-profile/my-contact" className={`${segment2 === "my-contact" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>My Contact</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/my-profile/my-profile" className={`${segment2 === "my-profile" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>My Profile</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/my-profile/my-resume" className={`${segment2 === "my-resume" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>My resume</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/my-profile/my-referrals" className={`${segment2 === "my-referrals" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>My Referrals</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/my-profile/my-documents" className={`${segment2 === "my-documents" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>My documents</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/my-profile/my-dk-resume" className={`${segment2 === "my-dk-resume" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>My DK Resume</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/my-profile/my-desired-job" className={`${segment2 === "my-desired-job" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>My Desired Job</Link>
                              </li>
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="dkg-cand-mo-menu-acc-Card"> 
                        <Accordion.Toggle className={isSettings ? "dkg-cand-mo-menu-acc-toggle active" : "dkg-cand-mo-menu-acc-toggle"} onClick={showsettingToggle} as={Card.Header} eventKey="2">
                          <Link to="/settings/faq"  data-toggle="dkg-cand-mo-submenu" className={`${segment1 === "settings" ? "dkg-cand-mo-menu-link dkg-chevronupdown-link grey-active" : "dkg-cand-mo-menu-link dkg-chevronupdown-link"}`}>
                            <span className="dkg-mo-sidemenu-icon"><FaCog/></span>
                            Settings <i className={isSettings ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
                          </Link>
                        </Accordion.Toggle>
                        <Accordion.Collapse className="dkg-cand-mo-menu-acc-collapse" eventKey="2">
                          <Card.Body className="dkg-cand-menu-acc-cardbody">
                            <ul className="dkg-cand-mo-acc-submenu">
                              <li className="dkg-cand-submenu-item">
                                <Link to="/settings/faq" className={`${segment2 === "faq" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>FAQ</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/settings/privacy" className={`${segment2 === "privacy" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Privacy</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/settings/gdpr-explained" className={`${segment2 === "gdpr-explained" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>GDPR</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/settings/terms-of-use" className={`${segment2 === "terms-of-use" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Terms</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/settings/cookies" className={`${segment2 === "cookies" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Cookies</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/settings/notifications" className={`${segment2 === "notifications" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Notifications</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/settings/give-feedback" className={`${segment2 === "give-feedback" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Give Feedback</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/settings/reset-logins" className={`${segment2 === "reset-logins" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Reset Logins</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/settings/delete-account" className={`${segment2 === "delete-account" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>Delete Account</Link>
                              </li>
                              <li className="dkg-cand-submenu-item">
                                <Link to="/settings/about-us" className={`${segment2 === "about-us" ? "dkg-cand-mo-acc-submenu-link" : "dkg-cand-mo-acc-submenu-link"}`}>About Us</Link>
                              </li>
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                </ul>
           </nav>
        </section>
     </div>
    </>
  );
};

export default MyAccountNav;
