import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./service-mdal.scss";

const ServicesModal = ({ onHide , headerTitle }) => {
  return (
    <>
      <Modal
       show={true}
       onHide={onHide}
        className="dkg-service-box-modal-21"
      >
        <Modal.Header closeButton className="dkg-services-modalheader-21">
          <Modal.Title>{headerTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-services-modalbody-21">
          
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ServicesModal;
