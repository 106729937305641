import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import Pagetypealbums from "../../../../../../../../assets/images/album.jpg";
import FullImageModal from "./FullImageModal";
import  './upload-video.scss'

const ViewalbumsModal = ({ onHide, albumimg, albumnme, albumid }) => {
  const [isFullImage, setIsFullImage] = useState(false);

  const showfullimageModal = () => {
    setIsFullImage(true);
  };
  const hidefullimageModal = () => {
    setIsFullImage(false);
  };

  const filteredData = albumimg.filter((item) => item.albumId === albumid);

  return (
    <>
      {isFullImage ? (
        <FullImageModal
          hideFullImage={hidefullimageModal}
          imglist={filteredData}
        />
      ) : null}
      <Modal
        show={true}
        onHide={onHide}
        className="dkg-pagetype-album-ablum-Modal"
        centered
      >
        <Modal.Header closeButton className="dkg-video-ablum-Header">
          <Modal.Title className="w-100 text-center">{albumnme}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-image-ablum-Body">
          <div className="dkg-album-ablum-mainCon">
            <div className="dkg-album-ablum-boxs">
              {filteredData.map((item, index) => (
                <div className="dkg-album-ablum-cols" key={index + 1}>
                  <div className="dkg-album-file-wrapper">
                    <span className="dk-myprofile-userImgCon">
                      <figure id="edit_image">
                        {
                          <img
                            alt=""
                            onClick={showfullimageModal}
                            className={
                              `dkg-changIcon changeThirdImageDynamic` + item.id
                            }
                            style={{ width: "80%", height: "80%" }}
                            src={
                              item.imageName !== "" && item.imageName !== null
                                ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                  "/" +
                                  item.imageName
                                : Pagetypealbums
                            }
                          />
                        }
                      </figure>
                    </span>
                    {/* <span className="dk-myprofile-editicon">
                      <FaEdit />
                    </span> */}
                  </div>
                </div>
              ))}
            </div>
            <div className="dkg-view-ablum-btnCon">
              <button className="dkg-view-ablum-btn btn">
                Click Image to View Album
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewalbumsModal;
