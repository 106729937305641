import React, { useState, useCallback, useRef, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import DummyUser from "../../../../../assets/images/user_dummay.png";
import {
  getCMS,
  // updatecontent,
  // clearState,
} from "../../../../../slice/cmsSlice";
import SubmitButton from "../../../../../components/ui/submitButton";

import AWS from "aws-sdk";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {Modal, Dropdown} from "react-bootstrap";
import { Buffer } from "buffer";
import { updateCanDetails } from "../../../../../slice/auth/authSlice";
import {
  updateTable,
  candidateProfileDetails,
} from "../../../../../slice/candidates/list/listSlice";

const AccountInfo = () => {
  const dispatch = useDispatch();
  const { userDetails, userInfo } = useSelector((state) => state.auth);

  const { isProfileLoading, profileDetails } = useSelector(
    (state) => state.candidates
  );

  const [fullname, setFullName] = useState("");
  const [phone1, setPhone1] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [systemEmail, setSystemEmail] = useState("");
  const [nativelang, setNativeLang] = useState("");
  const [fluentlang, setFluentLang] = useState("");

  const [phone2, setPhone2] = useState("");
  const [email2, setEmail2] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [Ccity, setCcity] = useState("");
  const [Ccountry, setCcountry] = useState("");
  const [state, setState] = useState("");
  const [postal, setPostal] = useState("");

  const [dobdate, setDobdate] = useState("");
  const [dobmonth, setDobmonth] = useState("");
  const [dobyear, setDobyear] = useState("");

  useEffect(() => {
    const fetchCandidateRecods = async () => {
      dispatch(candidateProfileDetails({ id: userInfo.canId }));
    };
    fetchCandidateRecods();
  }, []);

  useEffect(() => {
    if (!isProfileLoading && profileDetails) {
      setFullName(profileDetails.first_name + " " + profileDetails.last_name);
      setPhone1(profileDetails.phone1);
      setPrimaryEmail(profileDetails.email1);
      setSystemEmail(profileDetails.system_email);
      setNativeLang(profileDetails.native1);
      setFluentLang(profileDetails.fluent1);

      setPhone2(profileDetails.phone2);
      setEmail2(profileDetails.email2);
      setHomeAddress(profileDetails.address);
      setCcity(profileDetails.c_city);
      setState(profileDetails.state);
      setCcountry(profileDetails.c_country);
      setPostal(profileDetails.postal);
      setDobdate(profileDetails.dob_date);
      setDobmonth(profileDetails.dob_month);
      setDobyear(profileDetails.dob_year);
    }
  }, [isProfileLoading]);

  //image crop start
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [file, setFile] = useState(userInfo.profileImage);
  const [isImageUpdate, setIsImageUpdate] = useState(false);
  const [newImageData, setNewImageData] = useState("");
  const [profileImage, setProfileImage] = useState(userInfo.profileImage);

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 150,
    height: 150,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [cropModal, setCropModal] = useState(false);

  useEffect(() => {
    if (userDetails.profileImage != "users/images/user.png") {
      setFile(userDetails.profileImage);
    }
  }, []);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setCropModal(true);
    }
  };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    const base64Image = canvas.toDataURL("image/jpeg");
    //setIsImageUpdate(true);
    setNewImageData(base64Image);
  }, [completedCrop]);

  const handleClose = () => {
    setCropModal(false);
  };
  const handleCropDone = () => {
    // image upload
    console.log("submit crop");
    let s3bucket = new AWS.S3({
      accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
      secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
      Bucket: process.env.REACT_APP_BUCKET_NAME,
    });
    let buf = new Buffer(
      newImageData.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    s3bucket.createBucket(function () {
      var params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME + "/users/images",
        Key: Date.now() + ".png",
        ContentEncoding: "base64",
        ContentType: "image/png",
        Body: buf,
      };
      s3bucket.upload(params, function (err, data) {
        if (err) {
          console.log(err);
        } else {
          setProfileImage(data.key);
          dispatch(
            updateCanDetails({
              id: userInfo.id,
              profileImage: "/" + data.key,
            })
          );
        }
      });
    });
    setCropModal(false);
  };

  const cancelCrop = () => {
    setCropModal(false);
    setNewImageData("");
    setIsImageUpdate(false);
    setCompletedCrop(null);
  };
  //image crop end

  const { isSuccess, cmsList, isError, isInsert, msg, isLoading } = useSelector(
    (state) => state.cmsdata
  );
  // const [details, setDetails] = useState("");
  const [requireddat, setRequireddat] = useState("");
  const [optionaldata, setOptionaldata] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setRequireddat(cmsList[27].description);
      setOptionaldata(cmsList[28].description);
      //setDetails(cmsList);
    }
  }, [isSuccess]);

  const updateCandidateInfo = async (key, value) => {
    await dispatch(updateTable({ id: userInfo.id, key, value }));
  };

  return (
    <>
      {cropModal ? (
        <Modal
          className="dk-uploadImgModal"
          centered
          show={true}
          onHide={handleClose}
        >
          <Modal.Body>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-12">
              <div className="d-flex justify-content-center btn-container">
                <button className="dk-cancelBtn" onClick={cancelCrop}>
                  Cancel
                </button>
                <SubmitButton
                  txt="Use This"
                  loading={loading}
                  success={success}
                  onClick={handleCropDone}
                  position=""
                  className="dk-updateBtn"
                />
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}

      <div className="dkg-myprofile-mainpageCon">
        <div className="dkg-myprofile-con">
          <div className="dkg-myprofile-mainRow row m-0">
            <div className="dkg-myprofile-leftcon dkg-profile-info-leftCon col-md-6">
              <h2 className="dkg-profile-frm-title">ACCOUNT INFORMATION</h2>
              <div className="dkg-profile-frm-subtitle">
                <p className="dkg-frm-subtitle-desc">{requireddat}</p>
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                Status<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control dkg-profile-input dkg-blue-placeholder"
                  name="satus"
                  id="satus"
                  placeholder="Active"
                  readOnly
                />
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                Account ID<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control dkg-profile-input dkg-blue-placeholder"
                  name="account-id"
                  id="account-id"
                  placeholder="59226"
                  readOnly
                />
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                 Account Type<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="email"
                  className="form-control dkg-profile-input dkg-blue-placeholder"
                  name="account-type"
                  id="account-type"
                  placeholder="Jobseeker"
                  readOnly
                />
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                Subscription<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="email"
                  className="form-control dkg-profile-input dkg-blue-placeholder"
                  name="dk-email"
                  id="dk-email"
                  placeholder="Basic [Free]"
                  readOnly
                />
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                Account Created<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control dkg-profile-input dkg-blue-placeholder"
                  name="acc-created"
                  id="acc-created"
                  placeholder="01/01/2024"
                />
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                Account Accessed<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="email"
                  className="form-control dkg-profile-input dkg-blue-placeholder"
                  name="backup-mail"
                  id="backup-mail"
                  placeholder="13-04-2024 08:11:15"
                />
              </div>
            </div>
            <div className="dkg-myprofile-rightcon dkg-profile-info-rightCon col-md-6">
              <h2 className="dkg-profile-frm-title">OPTIONAL INFORMATION</h2>
              <div className="dkg-profile-frm-subtitle">
                <p className="dkg-frm-subtitle-desc">{optionaldata}</p>
              </div>
              <div className="d-md-flex align-items-center justify-content-between w-100">
                <div className="col-md-12 d-flex">
                  <label htmlFor="" className="dkg-profile-label"></label>
                  <div className="form-group dkg-profile-frmgrp pl-0 pr-0">
                    <label htmlFor="" className="dkg-profile-label">
                      Profile Image
                    </label>
                    <div className="dkg-info-uplaod-image">
                      <div className="d-flex align-items-center">
                        <div className="dk-edit-userImgCon">
                          <input
                            type="file"
                            accept="image/*"
                            name=""
                            onChange={onSelectFile}
                          />
                          <span className="dk-myprofile-userImgCon">
                            <figure id="edit_image">
                              {completedCrop ? (
                                <canvas
                                  ref={previewCanvasRef}
                                  className="dkg-update-profile-234"
                                />
                              ) : (
                                <img
                                  alt="Crop"
                                  id="cropImgId"
                                  className="dk-myprofile-userImg"
                                  src={
                                    process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                    userInfo.profileImage
                                  }
                                />
                              )}
                            </figure>
                          </span>
                          <span className="dk-myprofile-editicon">
                            <FaEdit />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-md-flex align-items-center justify-content-between w-100">
                <div className="col-md-12 d-flex">
                  <div
                    className="form-group dkg-profile-frmgrp pl-0 pr-0 "
                    style={{ marginTop: "0.542rem" }}
                  >
                    <label htmlFor="" className="dkg-profile-label">
                      Date of birth
                    </label>
                    <div className="dkg-dob-inputCon">
                      <input
                        type="text"
                        className="form-control dkg-dob-input"
                        name="day"
                        id="day"
                        placeholder="Date"
                        defaultValue={dobdate}
                        onChange={(e) =>
                          updateCandidateInfo("dob_date", e.target.value)
                        }
                      />
                      <input
                        type="text"
                        className="form-control dkg-dob-input"
                        name="day"
                        id="day"
                        placeholder="Month"
                        defaultValue={dobmonth}
                        onChange={(e) =>
                          updateCandidateInfo("dob_month", e.target.value)
                        }
                      />
                      <input
                        type="text"
                        className="form-control dkg-dob-input"
                        name="day"
                        id="day"
                        placeholder="Year"
                        defaultValue={dobyear}
                        onChange={(e) =>
                          updateCandidateInfo("dob_year", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-md-flex align-items-center justify-content-between w-100">
                <div className="col-md-12 d-flex">
                  <div
                    className="form-group dkg-profile-frmgrp pl-0 pr-0 "
                    style={{ marginTop: "0.542rem" }}
                  >
                    <label htmlFor="" className="dkg-profile-label">
                      Gender
                    </label>
                    <div className="dkg-app-select-dropdown-Con">
                      <Dropdown className="dkg-app-select-dropdown">
                        <Dropdown.Toggle variant="" id="" className="dkg-app-select-dropdown-toggle">
                         Male
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                          <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Male</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Female</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Other</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountInfo;
