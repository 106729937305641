import React,{useState , useRef , useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Dropdown  } from "react-bootstrap";
import DummyUser from "../../../../../assets/images/default-image.png";
import MaleAvatar  from "../../../../../assets/images/men-default.jpg";
import FemaleAvatar from "../../../../../assets/images/girl-defult.png";
import {FaImage , FaUpload , FaInfo } from "react-icons/fa"; 
import {
    getCMS,
    // updatecontent,
    // clearState,
} from "../../../../../slice/cmsSlice";

const ContactDetailsTab = () => {
    const dispatch = useDispatch();
    const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
    const [completedCrop, setCompletedCrop] = useState(null);
    const onSelectFile = (e) => {};
    const previewCanvasRef = useRef(null);
    const [contactInfo, setContactInfo] = useState("");
    useEffect(() => {
        dispatch(getCMS({}));
      }, [dispatch]);
    
      useEffect(() => {
        if (!isSuccess && cmsList.length > 0) {
          setContactInfo(cmsList[35].description);
        }
    }, [isSuccess]);
  return (
    <>
        <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">CONTACT DETAILS</h1>
            <span className="dkg-desired-info d-none">
            <FaInfo />
            </span>
        </div>
        <div className="dkg-createcv-job-mainCon">
            <div className="dkg-createcv-textarea-Con">
                <div className="form-control dkg-createcv-textarea">
                    {contactInfo}
                </div>
            </div>
        </div>
        <div className="dkg-create-cv-center-sec dkg-create-cv-job-centersec">
            <div className="dkg-create-cv-job-leftCon">
            <div className="dkg-selected-title">SELECT CV IMAGE</div>
            <div className="dkg-select-cv-cols">
                <div className="dkg-select-check-content">
                <div className="dkg-cv-select-checkbox-con">
                    <input type="checkbox" className="dkg-cv-select-checkbox" name="cv-select-checkbox" />
                    <span className="dkg-cv-checkbox-label">I do not want to use image in my resume.</span>
                </div>
                </div>
                <div className="dkg-uploadimg-mainCon">
                <div className="dkg-info-uplaod-image">
                    <div className="d-flex align-items-center">
                    <div className="dk-edit-userImgCon dk-edit-user-browseImgCon">
                        <input
                        type="file"
                        accept="image/*"
                        name=""
                        onChange={onSelectFile}
                        />
                        <span className="dk-myprofile-userImgCon dk-browse-edit-userImgCon ">
                        <figure id="edit_image dkg-edit-browse-image">
                            {completedCrop ? (
                            <canvas
                                ref={previewCanvasRef}
                                className="dkg-update-profile-234"
                            />
                            ) : (
                            // <img
                            //   alt="Crop"
                            //   id="cropImgId"
                            //   className="dk-myprofile-userImg"
                            //   src={DummyUser}
                            // />
                                <div className="dkg-browse-imge-textCon">
                                    <FaImage />
                                    <h3 className="dkg-browse-title">browse Image</h3>
                                </div>
                            )}
                        </figure>
                        </span>
                    </div>
                    </div>
                </div>
                <div className="dkg-upload-cv-btnCon">
                    {/* <button className="dkg-remove-cv-btn btn mr-2">
                    Remove{" "}
                    <span className="dkg-upload-icon ml-2">
                        <FaTimes />
                    </span>
                    </button> */}
                    <button className="dkg-upload-cv-btn btn">
                    Upload Image{" "}
                    <span className="dkg-upload-icon ml-2">
                        <FaUpload />
                    </span>
                    </button>
                </div>
                </div>
                <div className="dkg-uploadimg-mainCon">
                <div className="dkg-avatar-block-image-Con">
                    <div className="dkg-avatar-image-Con">
                    <img
                        alt="Crop"
                        id="cropImgId"
                        className="dkg-avatar-image"
                        src={MaleAvatar}
                    />
                    </div>
                    <div className="dkg-avatar-cv-btnCon">
                    <button className="dkg-avatar-cv-btn btn">
                    Use This Avatar
                    </button>
                    </div>
                </div>
                </div>
                <div className="dkg-uploadimg-mainCon">
                <div className="dkg-avatar-block-image-Con">
                    <div className="dkg-avatar-image-Con">
                    <img
                        alt="Crop"
                        id="cropImgId"
                        className="dkg-avatar-image"
                        src={FemaleAvatar}
                    />
                    </div>
                    <div className="dkg-avatar-cv-btnCon">
                    <button className="dkg-avatar-cv-btn btn">
                    Use This Avatar
                    </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="dkg-create-cv-job-rightCon">
            <div className="dkg-selected-title">
                CONTACT DETAILS
            </div>
            <div className="dkg-createcv-rightbody">
                <div className="dkg-createcv-frmgrp">
                <label htmlFor="" className="dkg-createcv-label">
                    Full Name
                </label>
                <input
                    type="text"
                    className="dkg-createcv-frm-control form-control"
                />
                </div>
                {/* <div className="dkg-createcv-frmgrp">
                <label htmlFor="" className="dkg-createcv-label">
                    Home Address
                </label>
                <input
                    type="text"
                    className="dkg-createcv-frm-control form-control"
                />
                </div> */}
                <div className="dkg-createcv-frmgrp">
                <label htmlFor="" className="dkg-createcv-label">
                City/Town
                </label>
                <input
                    type="text"
                    className="dkg-createcv-frm-control form-control"
                />
                </div>
                <div className="dkg-createcv-frmgrp">
                <label htmlFor="" className="dkg-createcv-label">
                Country
                </label>
                <input
                    type="text"
                    className="dkg-createcv-frm-control form-control"
                />
                </div>
                <div className="dkg-createcv-frmgrp">
                <label htmlFor="" className="dkg-createcv-label">
                Nationality
                </label>
                <input
                    type="text"
                    className="dkg-createcv-frm-control form-control"
                />
                </div>
                <div className="dkg-createcv-frmgrp">
                <label htmlFor="" className="dkg-createcv-label">
                    Email Address
                </label>
                <input
                    type="email"
                    className="dkg-createcv-frm-control form-control"
                />
                </div>
                <div className="dkg-createcv-frmgrp">
                <label htmlFor="" className="dkg-createcv-label">
                    Phone Number
                </label>
                <input
                    type="text"
                    className="dkg-createcv-frm-control form-control"
                />
                </div>
                <div className="dkg-createcv-frmgrp">
                <label htmlFor="" className="dkg-createcv-label">
                    Date of Birth
                </label>
                <input
                    type="date"
                    className="dkg-createcv-frm-control form-control"
                />
                </div>
                {/* <div className="dkg-createcv-frmgrp">
                                    <label htmlFor="" className='dkg-createcv-label'>Marital Status</label>
                                    <div className="dkg-createcv-dropdwnCon">
                                    <Dropdown className="dkg-createcv-dropdwn">
                                        <Dropdown.Toggle variant="" className="dkg-ccv-dropdwn-toggle" id="">
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dkg-ccv-dropdwn-menu">
                                            <Dropdown.Item href="#" className="dkg-ccv-dropdwn-item">Yes</Dropdown.Item>
                                            <Dropdown.Item href="#" className="dkg-ccv-dropdwn-item">No</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                </div> */}
                <div className="dkg-createcv-frmgrp">
                <label htmlFor="" className="dkg-createcv-label">
                EU VISA Status
                </label>
                <div className="dkg-createcv-dropdwnCon">
                    <Dropdown className="dkg-createcv-dropdwn">
                    <Dropdown.Toggle
                        variant=""
                        className="dkg-ccv-dropdwn-toggle"
                        id=""
                    ></Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-ccv-dropdwn-menu">
                        <Dropdown.Item
                        href="#"
                        className="dkg-ccv-dropdwn-item"
                        >
                        Yes
                        </Dropdown.Item>
                        <Dropdown.Item
                        href="#"
                        className="dkg-ccv-dropdwn-item"
                        >
                        No
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                </div>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}

export default ContactDetailsTab