import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData, PostData, PutData, DeleteData } from "../../services";

export const gettermsdata = createAsyncThunk(
    "cmsdetails/cms/termscondtion",
    async (thunkAPI) => {
        const res = await GetData(true, "/terms-condition/list");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updatecontent = createAsyncThunk(
    "cmsdetails/cms/update",
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, "/mangecontent/" + id, { id, key, value });
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateNotification = createAsyncThunk(
    "cmsdetails/notififcation/update",
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, "/mangecontent/m-notifications/" + id, { id, key, value });
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateCookies = createAsyncThunk(
    "cmsdetails/coockies/update",
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, "/mangecontent/m-cockies/" + id, { id, key, value });
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const getCookiesNotification = createAsyncThunk(
    "cmsdetails/Cookies/Notification",
    async ({ canId }, thunkAPI) => {
        const res = await GetData(true, "/mangecontent/getCN/" + canId);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getAboutService = createAsyncThunk(
    "cmsdetails/cms/aboutservice",
    async (thunkAPI) => {
        const res = await GetData(true, "/mangecontent/aboutusservices");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getCMS = createAsyncThunk(
    "cmsdetails/cms/text-change",
    async (thunkAPI) => {
        const res = await GetData(true, "/mangecontent");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deletdocuments = createAsyncThunk(
    "cmsdetails/delte/document",
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, "/mangecontent/mydocument/" + id);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deleteCanditateAccount = createAsyncThunk(
    "cmsdetails/delte/account",
    async ({ canid, terms, name, from, phone, subject, message }, thunkAPI) => {
        const res = await PostData(true, "/send-email", { canid, terms, name, from, phone, subject, message });
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const submitQuery = createAsyncThunk(
    "cmsdetails/qeryForm",
    async ({ canid, terms, name, from, phone, subject, message }, thunkAPI) => {
        const res = await PostData(true, "/send-email", { canid, terms, name, from, phone, subject, message });
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const addNewDocument = createAsyncThunk(
    "cmsdetails/adddoc",
    async ({ uid, canId, fileName, onlyfileName, fileType, fileCategory, fileUsedIn, docDate }, thunkAPI) => {
        const res = await PostData(true, "/mangecontent/my-document", { uid, canId, fileName, onlyfileName, fileType, fileCategory, fileUsedIn, docDate });
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const filesCategory = createAsyncThunk(
    'cmsdetails/filecategory',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateMydocument = createAsyncThunk(
    "cmsdetails/update/adddoc",
    async ({ id, canId, fileName, onlyfileName, fileType, fileCategory, docDate }, thunkAPI) => {
        const res = await PutData(true, "/mangecontent/mydocument/" + id, { canId, fileName, onlyfileName, fileType, fileCategory, docDate });
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getprojectData = createAsyncThunk(
    "cmsdetails/projectdetails",
    async (thunkAPI) => {
        const res = await GetData(true, "/mangecontent/project-info");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const mydoclistId = createAsyncThunk(
    "cmsdetails/mydocument/id",
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, "/mangecontent/my-doc/" + id);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getMyDocumentData = createAsyncThunk(
    "cmsdetails/mydocument",
    async ({ canId }, thunkAPI) => {
        const res = await GetData(true, "/mangecontent/my-document/" + canId);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const usersSlice = createSlice({
    name: "cmsDetails",
    initialState: {
        cmsList: [],
        aboutusServiceList: [],
        termscondtionList: [],
        projectDataList: [],
        cookieNotification: [],
        mydocumentsList: [],
        filesCategoryList: [],
        msg: '',
        isSuccess: false,
        isBenifits: false,
        isCookiesN: false,
        isUpdate: false,
        isLoading: false,
        isError: false,
        isDelete: false,
        isInsert: false,
        isOperation: false,
        isAboutSuccess: false,
    },
    reducers: {
        clearState: (state) => {
            state.isOperation = false;
            state.isInsert = false;
            state.isLoading = false;
            state.isSuccess = false;
            state.isBenifits = false;
            state.isCookiesN = false;
            state.isError = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isAboutSuccess = false;
        },
    },
    extraReducers: {

        [getMyDocumentData.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getMyDocumentData.fulfilled]: (state, { payload }) => {
            state.isSuccess = false;
            state.isLoading = false;
            state.mydocumentsList = payload.result;
        },
        [getMyDocumentData.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isSuccess = false;
            state.isLoading = false;
        },
        [getprojectData.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            //state.isSuccess = false;
        },
        [getprojectData.fulfilled]: (state, { payload }) => {
            state.isSuccess = false;
            state.projectDataList = payload.result;
        },
        [getprojectData.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isSuccess = false;
        },
        [gettermsdata.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [gettermsdata.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.termscondtionList = payload.result;
        },
        [gettermsdata.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateNotification.pending]: (state) => {
            state.isOperation = false;
            state.isError = false;
        },
        [updateNotification.fulfilled]: (state, { payload }) => {
            state.isOperation = true;
        },
        [updateNotification.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isOperation = false;
        },
        [updateCookies.pending]: (state) => {
            state.isOperation = false;
            state.isError = false;
        },
        [updateCookies.fulfilled]: (state, { payload }) => {
            state.isOperation = true;
        },
        [updateCookies.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isOperation = false;
        },
        [updatecontent.pending]: (state) => {
            state.isUpdate = true;
            state.isError = false;
        },
        [updatecontent.fulfilled]: (state, { payload }) => {
            state.isUpdate = false;
        },
        [updatecontent.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isUpdate = false;
        },

        [getCookiesNotification.pending]: (state) => {
            state.isCookiesN = true;
            state.isError = false;
        },
        [getCookiesNotification.fulfilled]: (state, { payload }) => {
            state.isCookiesN = false;
            state.cookieNotification = payload.result;
        },
        [getCookiesNotification.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isCookiesN = false;
        },
        [getAboutService.pending]: (state) => {
            state.isAboutSuccess = false;
            state.isError = false;
            state.isLoading = false;
        },
        [getAboutService.fulfilled]: (state, { payload }) => {
            state.isAboutSuccess = true;
            state.aboutusServiceList = payload.result;
        },
        [getAboutService.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isAboutSuccess = false;
        },
        [getCMS.pending]: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isLoading = false;
        },
        [getCMS.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            state.cmsList = payload.result;
        },
        [getCMS.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isSuccess = false;
        },
        [updateMydocument.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isUpdate = false;
        },
        [updateMydocument.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            state.mydocumentsList = payload.result;
            state.msg = payload.message
        },
        [updateMydocument.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            state.isError = true;
        },

        [filesCategory.pending]: (state) => {
            state.isLoading = true;
        },
        [filesCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.filesCategoryList = payload.result;
        },
        [filesCategory.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
        },

        [deletdocuments.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isDelete = false;
        },
        [deletdocuments.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isDelete = true;
        },
        [deletdocuments.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isDelete = true;
            state.isError = true;
        },
        [addNewDocument.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isInsert = false;
        },
        [addNewDocument.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.mydocumentsList = payload.result;
            state.msg = payload.message
        },
        [addNewDocument.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.isError = true;
        },
        [submitQuery.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isInsert = false;
        },
        [submitQuery.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.msg = payload.message
        },
        [submitQuery.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message
        },
        [deleteCanditateAccount.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [deleteCanditateAccount.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
        },
        [deleteCanditateAccount.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
        },


    },
});

export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
