import React,{useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { FaInfo } from "react-icons/fa"; 
import {getCMS} from "../../../../../slice/cmsSlice"
import AddNewExpModal from './modal/AddNewExp'
import AddGapModal from './modal/AddGapModal'
import NoExpModal from './modal/NoExpModal'

const WrokexpTab = () => {
    const dispatch = useDispatch();
    const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
    const [workexp, setWorkexp] = useState("");
    const [isAddnewExp , setIsAddnewExp]= useState(false);
    const [isAddgapExp , setIsAddgapExp]= useState(false);
    const [isNoExp , setIsNoExp]= useState(false);

    useEffect(() => {
        dispatch(getCMS({}));
      }, [dispatch]);
      useEffect(() => {
        if (!isSuccess && cmsList.length > 0) {
          setWorkexp(cmsList[37].description);
        }
      }, [isSuccess]);
      const showaddnewexpModal = () =>{
        setIsAddnewExp(true)
      }
      const hideaddnewexpModal = () =>{
        setIsAddnewExp(false)
      } 
      const showaddgapexpModal = () =>{
        setIsAddgapExp(true)
      }
      const hideaddgapexpModal = () =>{
        setIsAddgapExp(false)
      }
      const shownoexpModal = () =>{
        setIsNoExp(true)
      }
      const hidenoexpModal = () =>{
        setIsNoExp(false)
      } 
  return (
    <>
        {
            (isAddnewExp)? <AddNewExpModal  onHide={hideaddnewexpModal} /> : null
        }
        {
            (isAddgapExp)? <AddGapModal  onHide={hideaddgapexpModal} /> : null
        }
        {
            (isNoExp)? <NoExpModal  onHide={hidenoexpModal} /> : null
        }
        <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">Work Experience</h1>
            <span className="dkg-desired-info d-none">
            <FaInfo />
            </span>
        </div>
        <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
            <div className="dkg-desired-textarea-Con">
            <div className="form-control dkg-desired-textarea">
                {workexp}
            </div>
            </div>
            <div className="dkg-cvpreview-center-sec dkg-workexpirece-mainCon">
            <div className="dkg-workexpirece-rightcon">
                <div className="dkg-work-exp-right-header">
                <div className="dkg-work-exp-right-title">YOUR WORK EXPERIENCE</div>
                    <ul className="dkg-work-exp-right-btn-ul">
                    <li className="dkg-work-exp-right-btn-li"> 
                        <Link to="#" className="dkg-work-exp-right-btn" onClick={showaddnewexpModal} >Add New Exp</Link>
                    </li>
                    <li className="dkg-work-exp-right-btn-li">
                        <Link to="#"  className="dkg-work-exp-right-btn"  onClick={showaddgapexpModal}>Add Gap Exp</Link>
                    </li>
                    <li className="dkg-work-exp-right-btn-li">
                        <Link to="#"  className="dkg-work-exp-right-btn" onClick={shownoexpModal}> Add No Exp</Link>
                    </li>
                    </ul>
                </div>
                <div className="dkg-work-exp-textarea-Con">
                <textarea name="" id="" className="dkg-work-exp-textarea" defaultValue="" />
                </div>
            </div>
            </div>
        </div>
    </>
  )
}

export default WrokexpTab