import React, { useState } from "react";
import  './service-overview.scss'
import { FeaturedPlayListRounded } from "@material-ui/icons";
import Onlineservice from '../../../../../assets/images/online-course.png'
import ServiceConsult from '../../../../../assets/images/service-consult.png'
import Serviceemailsupport from '../../../../../assets/images/email-support.png' 
import ServiceeOnline from '../../../../../assets/images/service-online.png' 
import MentoringService from '../../../../../assets/images/managejobs_icon.png'
import WorkingExperience from '../../../../../assets/images/work-experience.png'

import ServicesModal from './modal/ServicesModal'
import SendqueryModal from './modal/SendqueryModal'

import {  FaPaperPlane } from "react-icons/fa";


const Paidserveoverview = () => {
  const [isSendQuery , setIsSendQuery ] = useState(false);
  const [isServiceModal , setIsServiceModal ] = useState(false);
  const [isConsultModal , setIsConsultModal ] = useState(false);
  const [isMentorShip , setIsMentorShip ] = useState(false);
  const [isIntershipModal , setIsIntershipModal ] = useState(false);
  const [isOnlineToolModal , setIsOnlineToolModal ] = useState(false);
  const [isEmailSupportModal , setIsEmailSupportModal ] = useState(false);


 const showconsultModal = () =>{
  setIsConsultModal (true)
 }
 const hideconsultModal = () =>{
  setIsConsultModal (false)
 }
 const showmentorshipModal = () =>{
  setIsMentorShip (true)
 }
 const hidementorshipModal = () =>{
  setIsMentorShip (false)
 } 
 const showInternshipModal = () =>{
  setIsIntershipModal (true)
 }
 const hideInternshipModal = () =>{
  setIsIntershipModal (false)
 }
 const showonlinetoolModal = () =>{
  setIsOnlineToolModal (true)
 }
 const hideonlinetoolModal = () =>{
  setIsOnlineToolModal (false)
 }
 const showemailsupportModal = () =>{
  setIsEmailSupportModal (true)
 }
 const hideemailsupportModal = () =>{
  setIsEmailSupportModal (false)
 }
  const showserviceModal = () =>{
    setIsServiceModal(true)
  }
  const hideserviceModal = () =>{
    setIsServiceModal(false)
  }
  const showsendqueryModal = () =>{
    setIsSendQuery(true)
  }
  const hidesendqueryModal = () =>{
    setIsSendQuery(false)
  }
  return (
    <> 
      {
        (isConsultModal)? <ServicesModal onHide={hideconsultModal} headerTitle="Consultation" /> : null
      }
      {
        (isMentorShip)? <ServicesModal onHide={hidementorshipModal} headerTitle="Mentorship" /> : null
      }
      {
        (isIntershipModal)? <ServicesModal onHide={hideInternshipModal} headerTitle="Intership" /> : null
      }
      {
        (isOnlineToolModal)? <ServicesModal onHide={hideonlinetoolModal} headerTitle="Online Tool" /> : null
      }
      {
        (isEmailSupportModal)? <ServicesModal onHide={hideemailsupportModal} headerTitle="Email Support" /> : null
      }
       {
        (isServiceModal)? <ServicesModal onHide={hideserviceModal} headerTitle="Online Course" /> : null
      }
      {
        (isSendQuery) ? <SendqueryModal onHide={hidesendqueryModal} /> : null
      }
      <div className="dkg-paidservices-homeMain"> 
        <div className="dkg-paidservices-main-banner">
          <div className="dkg-paidservice-main-con">
            <div className="dkg-service-btncontent-con">
              <div className="dkg-paidservice-content">
                <h2 className="dkg-paidservice-title">We are “DK Global Consultants”</h2>
                <p className="dkg-paidservice-subtitle">
                  DK Global Consultants is a consultants solution for consultant and job seeker are available.
                </p>
              </div>
              <div className="dkg-book-appointment-btnCon">
                <button className="btn dkg-book-appointment-btn" onClick={showsendqueryModal}>Send Your Query
                  <span className="dkg-calender-icon">
                    <FaPaperPlane />
                  </span>
                </button>
              </div>
            </div>
            <div className="dkg-paidservice-bottom-sec">
              <div className="dkg-paidservice-bottom-ul">
                <li className="dkg-paidservice-bottom-li" onClick={showconsultModal}>
                  <div className="dkg-service-icon-con">
                    <img src={ServiceConsult}  className="dkg-service-icon" alt=""/>
                  </div>
                  <div className="dkg-service-name">Consultation</div> 
                </li>
                <li className="dkg-paidservice-bottom-li" onClick={showmentorshipModal}>
                  <div className="dkg-service-icon-con">
                    <img src={MentoringService}  className="dkg-service-icon" alt="Mentoring"/>
                  </div>
                  <div className="dkg-service-name">Mentorship</div>
                </li>
                <li className="dkg-paidservice-bottom-li" onClick={showInternshipModal}>
                  <div className="dkg-service-icon-con">
                    <img src={WorkingExperience}  className="dkg-service-icon" alt="Mentoring"/>
                  </div>
                  <div className="dkg-service-name">Inernship</div>
                </li>
                <li className="dkg-paidservice-bottom-li" onClick={showonlinetoolModal}>
                  <div className="dkg-service-icon-con">
                    <img src={ServiceeOnline}  className="dkg-service-icon" alt="Online Tool"/>
                  </div>
                  <div className="dkg-service-name">Online Tool</div>
                </li>
                <li className="dkg-paidservice-bottom-li" onClick={showemailsupportModal}>
                  <div className="dkg-service-icon-con">
                    <img src={Serviceemailsupport}  className="dkg-service-icon" alt=""/>
                  </div>
                  <div className="dkg-service-name">Email Support</div>
                </li>
                <li className="dkg-paidservice-bottom-li" onClick={showserviceModal}>
                  <div className="dkg-service-icon-con">
                    <img src={Onlineservice}  className="dkg-service-icon" alt=""/>
                  </div>
                  <div className="dkg-service-name">Online Course</div>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paidserveoverview;
