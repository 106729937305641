import React from "react";
import Modal from "react-bootstrap/Modal";
import  './addnew-exp.scss'

const AddGapModal = ({ onHide }) => {

  return (
    <>
       <Modal
       show={true}
       onHide={onHide}
       className="dkg-addnew-experience-modal-21"
      >
        <Modal.Header closeButton className="dkg-addnew-experience-modalheader-21">
          <Modal.Title>ADD GAP EXPERIENCE</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-addnew-experience-modalbody-21">
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddGapModal;
