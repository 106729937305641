import React, { useState, useEffect } from "react";
// import  './dkg-myprofile.scss'
import { useDispatch, useSelector } from "react-redux";
//import CV from "../../../../../assets/images/cvimg.png";
import CVupload from "../../../../../assets/images/cvimg.png";
import { FaEye, FaUpload, FaTimes } from "react-icons/fa";
import CandidateDocumentModal from "../../../../pages/candidate/pipeline/modal/documents/profileDocument";
import { getCMS } from "../../../../../slice/cmsSlice";

const DownloadCv = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [isCVPoup, setIsCVPoup] = useState(false);
  const dispatch = useDispatch();
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  const [details, setDetails] = useState("");
  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[31].description);
    }
  }, [isSuccess]);

  // const openCV = () => {
  //   setIsCVPoup(true);
  // };
  return (
    <>
      {isCVPoup ? (
        <CandidateDocumentModal name="CV" canId={userInfo.canId} />
      ) : (
        ""
      )}

      <div className="dkg-myprofile-mainpageCon dkg-myresume-mainCon dkg-candidate-myres-mainCon">
        <div className="dkg-myprofile-con  dkg-myresume-con">
          <div
            className="dkg-myprofile-mainRow  dkg-myresume-MainRow row m-0"
            style={{ height: "calc(100vh - 200px)" }}
          >
            <div className="dkg-myprofile-rightcon dkg-resume-rightcon col-md-8 m-auto">
              <h2 className="dkg-profile-frm-title dkg-resume-frm-title">
                MY CURRENT RESUME
              </h2>
              <div className="dkg-profile-frm-subtitle">
                <p className="dkg-frm-subtitle-desc">{details}</p>
              </div>
              <div className="dkg-resume-rightbody">
                <div className="dkg-white-block">
                  <div className="dkg-myresume-cvimgCon">
                    <span className="dkg-myresume-sapnimgCon">
                      <img
                        src={CVupload}
                        className="dkg-myresume-cvimg"
                        alt="CV"
                        style={{ cursor: "text" }}
                      />
                    </span>
                  </div>
                </div>
                <div className="dkg-resume-btngrp-Con">
                  <div className="dkg-resume-btn dkg-myresume-cvopen-con">
                    <CandidateDocumentModal
                      name={
                        <button className="btn dkg-resume-view-btn">
                          Click Here To View CV
                          <span className="dkg-resume-icon">
                            <FaEye />
                          </span>
                        </button>
                      }
                      canId={userInfo.canId}
                    />
                  </div>
                  {/* <div className='dkg-resume-btn mr-2'>
                           <button className='btn dkg-resume-view-btn'>Upload<span className='dkg-resume-icon'><FaUpload /></span></button>
                           </div>
                           <div className='dkg-resume-btn'>
                           <button className='btn dkg-resume-view-btn'>Remove<span className='dkg-resume-icon'><FaTimes /></span></button>
                           </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadCv;
