import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updatedataValue,
  defaultJobsCompnay,
} from "../../../../../../slice/jobSpec/jobSpecSlice";
//import { showError } from "../../../../../utils/messages/messageSlice";

const ClientinfoTab = ({ jobId, clientid }) => {
  const dispatch = useDispatch();
  const { isLoading, isjobLoading, allCompanyList, companyList } = useSelector(
    (state) => state.jobsSpec
  );

  const [selectedId, setSelectedId] = useState(null);
  const [clist, setclist] = useState([]);

  useEffect(() => {
    if (!isjobLoading && allCompanyList && allCompanyList.length > 0) {
      const onlyCompanyId = allCompanyList.filter(
        (data) => data.jobsId === jobId
      );
      setSelectedId(onlyCompanyId.length > 0 ? onlyCompanyId[0].companyId : 0);
    }
  }, [isjobLoading]);

  useEffect(() => {
    if (!isLoading && companyList && companyList.length > 0) {
      setclist(companyList);
    }
  }, [isLoading]);

  const handleChange = (id, companyname) => {
    setSelectedId(id);
    dispatch(
      updatedataValue({
        id: id, //company id
        key: "isShowInJob",
        value: 1,
        jobsId: jobId, //job id
        clientId: clientid,
        clientname: companyname,
        tableName: "project_relationship_companies",
      })
    );
  };

  return (
    <>
      <div className="dkg-company-list-mainCon">
        <div className="dkg-company-list-tableCon table-responsive">
          <table className="dkg-company-list-table table table-borderded">
            <thead>
              <tr>
                <th>Companies</th>
                <th>Linked</th>
              </tr>
            </thead>
            <tbody>
              {clist.length > 0 ? (
                <>
                  {clist.map((details, index) => (
                    <tr key={index + 1}>
                      <td>{details.categoryName}</td>
                      <td>
                        <span className="dkg-clientinfo-checkbox">
                          <input
                            type="radio"
                            className="form-control"
                            name={`clientinfo-checkbox3`}
                            data-id={details.id}
                            data-companyname={details.categoryName}
                            id={details.id}
                            checked={selectedId === details.id}
                            onChange={() =>
                              handleChange(details.id, details.categoryName)
                            }
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ClientinfoTab;
