import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useSelector } from "react-redux";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default function CustomizedAccordions({ quesAnswer }) {

    const { isSuccess } = useSelector((state) => state.candidatefaqData);
    const [data, setData] = useState([]);
    useEffect(() => {
        if (isSuccess) {
            setData(quesAnswer);
        }
    }, [isSuccess]);

    const [expanded, setExpanded] = React.useState("panel0");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const textRef = React.useRef();
    const [value, setValue] = React.useState();
    // const onChnage = (event) => {
    //     setValue(event.target.value);
    // };
    React.useEffect(() => {
        if (textRef && textRef.current) {
            textRef.current.style.height = "0px";
            const taHeight = textRef.current.scrollHeight;
            textRef.current.style.height = taHeight + "px";
        }
    }, [value]);

    return (
        <>
                <div className='dkg-screen-faq-accodionCon'>
                    <Accordion
                        expanded={expanded == `panel` + 1}
                        onChange={handleChange(`panel` + 1)}
                        className="dkg-screen-faq-accodion"
                    >
                        <AccordionSummary
                            aria-controls={`panel` + 1 + `d-content`}
                            id={`panel` + 1 + `d-header`}
                            className="dkg-screen-faq-accodion-suMM"
                        >
                            <Typography>
                                {/* {queAns.question} */}
                                {/* {queAns.question != "" && queAns.question != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.question,
                                        }}
                                    ></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry Question

                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{ backgroundColor: "#24475f", color: "#fff" }}
                        >
                            <Typography>
                                
                                {/* {queAns.answer != "" && queAns.answer != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.answer,
                                        }}
                                        style={{
                                            backgroundColor: "#24475f",
                                            color: "#fff",
                                            border: "none",
                                        }}></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == `panel` + 2}
                        onChange={handleChange(`panel` + 2)}
                        className="dkg-screen-faq-accodion"
                    >
                        <AccordionSummary
                            aria-controls={`panel` + 2 + `d-content`}
                            id={`panel` + 2 + `d-header`}
                            className="dkg-screen-faq-accodion-suMM"
                        >
                            <Typography>
                                {/* {queAns.question} */}
                                {/* {queAns.question != "" && queAns.question != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.question,
                                        }}
                                    ></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry Question

                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{ backgroundColor: "#24475f", color: "#fff" }}
                        >
                            <Typography>
                                
                                {/* {queAns.answer != "" && queAns.answer != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.answer,
                                        }}
                                        style={{
                                            backgroundColor: "#24475f",
                                            color: "#fff",
                                            border: "none",
                                        }}></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == `panel` + 3}
                        onChange={handleChange(`panel` + 3)}
                        className="dkg-screen-faq-accodion"
                    >
                        <AccordionSummary
                            aria-controls={`panel` + 3 + `d-content`}
                            id={`panel` + 3 + `d-header`}
                            className="dkg-screen-faq-accodion-suMM"
                        >
                            <Typography>
                                {/* {queAns.question} */}
                                {/* {queAns.question != "" && queAns.question != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.question,
                                        }}
                                    ></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry Question

                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{ backgroundColor: "#24475f", color: "#fff" }}
                        >
                            <Typography>
                                
                                {/* {queAns.answer != "" && queAns.answer != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.answer,
                                        }}
                                        style={{
                                            backgroundColor: "#24475f",
                                            color: "#fff",
                                            border: "none",
                                        }}></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == `panel` + 4}
                        onChange={handleChange(`panel` + 4)}
                        className="dkg-screen-faq-accodion"
                    >
                        <AccordionSummary
                            aria-controls={`panel` + 4 + `d-content`}
                            id={`panel` + 4 + `d-header`}
                            className="dkg-screen-faq-accodion-suMM"
                        >
                            <Typography>
                                {/* {queAns.question} */}
                                {/* {queAns.question != "" && queAns.question != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.question,
                                        }}
                                    ></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry Question

                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{ backgroundColor: "#24475f", color: "#fff" }}
                        >
                            <Typography>
                                
                                {/* {queAns.answer != "" && queAns.answer != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.answer,
                                        }}
                                        style={{
                                            backgroundColor: "#24475f",
                                            color: "#fff",
                                            border: "none",
                                        }}></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == `panel` + 5}
                        onChange={handleChange(`panel` + 5)}
                        className="dkg-screen-faq-accodion"
                    >
                        <AccordionSummary
                            aria-controls={`panel` + 5 + `d-content`}
                            id={`panel` + 5 + `d-header`}
                            className="dkg-screen-faq-accodion-suMM"
                        >
                            <Typography>
                                {/* {queAns.question} */}
                                {/* {queAns.question != "" && queAns.question != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.question,
                                        }}
                                    ></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry Question

                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{ backgroundColor: "#24475f", color: "#fff" }}
                        >
                            <Typography>
                                
                                {/* {queAns.answer != "" && queAns.answer != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.answer,
                                        }}
                                        style={{
                                            backgroundColor: "#24475f",
                                            color: "#fff",
                                            border: "none",
                                        }}></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == `panel` + 6}
                        onChange={handleChange(`panel` + 6)}
                        className="dkg-screen-faq-accodion"
                    >
                        <AccordionSummary
                            aria-controls={`panel` + 6 + `d-content`}
                            id={`panel` + 6 + `d-header`}
                            className="dkg-screen-faq-accodion-suMM"
                        >
                            <Typography>
                                {/* {queAns.question} */}
                                {/* {queAns.question != "" && queAns.question != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.question,
                                        }}
                                    ></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry Question

                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{ backgroundColor: "#24475f", color: "#fff" }}
                        >
                            <Typography>
                                
                                {/* {queAns.answer != "" && queAns.answer != null ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: queAns.answer,
                                        }}
                                        style={{
                                            backgroundColor: "#24475f",
                                            color: "#fff",
                                            border: "none",
                                        }}></div>
                                ) : (
                                    ""
                                )} */}
                                Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
        </>
    );
}