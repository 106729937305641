import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SimpleDropDwn from "./SimpleDropDwn";
import UserSearchFilter from "./SearchInputDropDwn";
import AirDatepicker from "air-datepicker";
import en from "air-datepicker/locale/en";
import "air-datepicker/air-datepicker.css";
import UserList from "../../../../../ui/userList/userListByRole";
import { BsInfo } from "react-icons/bs";
// import PophoverIcon from './PophoverIcon'

import {
  clearState,
  updateJob,
  updateJobWithTextBox,
} from "../../../../../../slice/jobs/jobsSlice";

const Summary = (jobId) => {
  const dispatch = useDispatch();

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setStatus(jobDetails.status);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);
  let ids = data.id;

  let gross_value = "";
  if (data.gross_title === null) {
    gross_value = "Monthly Gross";
  } else {
    if (data.gross_title === "Monthly Gross") {
      gross_value = "Annual Gross";
    } else {
      gross_value = "Monthly Gross";
    }
  }

  //
  let bgcolur = '';
  let txtColor = '';

  if (data.status == "Active") {
    bgcolur = "#02a786"
    txtColor = '#fff'
  }
  else if (data.status == "Inactive") {
    bgcolur = "#b55355"
    txtColor = '#fff'
  }
  else if (data.status == "Qualify") {
    bgcolur = "#d58b5d"
    txtColor = '#fff'
  }
  else if (data.status == "On Hold") {
    bgcolur = "#8b572a"
    txtColor = '#fff'
  }
  else if (data.status == "Closed") {
    bgcolur = "#da3d5e"
    txtColor = '#fff'
  }
  else {
    bgcolur = "#da3d5e"
    txtColor = '#fff'
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-8 col-lg-8">
              <div className="row">
                <div
                  className="col-md-6 pb-2 dk-suMMRightCon dk-jobsuMMBottomSec mt-0"
                >
                  <div className="dk-suMMRightConBoxBg">
                    <div className="dk-suMMRightBoxHeader">
                      <h3>Job Information  <span className="dkg-pophover-icon"><BsInfo /></span></h3>
                    </div>
                    <div className="dk-suMMRightboxBody">
                      <div className="dk-suMMformGroupCon" >
                        <label>Status</label>
                        <span style={{ backgroundColor: bgcolur, borderColor: bgcolur, color: txtColor }}>{data.status}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Job ID</label>
                        <span
                          className="font-weight-bold"
                          style={{ color: "#26597d" }}
                        >
                          {data.id}
                        </span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Total Jobs</label>
                        <span>{data.no_of_jobs}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Industry</label>
                        <span>{data.industry}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Company</label>
                        <span>{data.company}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Country</label>
                        <span>{data.job_country}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>City/Town</label>
                        <span>{data.location}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Project</label>
                        <span>{data.project}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Role Type</label>
                        <span>{data.role}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Language</label>
                        <span>{data.lang}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Contract</label>
                        <span>{data.contract}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Work Permit</label>
                        <span>{data.work_permit}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Work From</label>
                        <span>{data.workplace}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Work Type</label>
                        <span>{data.worktype}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Work Days</label>
                        <span>{data.workdays}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Work Hours</label>
                        <span>{data.shifts_work}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Start Date 1</label>
                        <span>{data.date1}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label>Start Date 2</label>
                        <span>{data.date2}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 pb-2 dk-suMMRightCon dk-jobsuMMBottomSec mt-0"
                  style={{ paddingLeft: "0.55rem" }}
                >
                  <div className="dk-suMMRightConBoxBg">
                    <div className="dk-suMMRightBoxHeader">
                      <h3>Job Requirements<span className="dkg-pophover-icon"><BsInfo /></span></h3>
                    </div>
                    <div className="dk-suMMRightboxBody">
                      {/* <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "116px" }}>Method</label>
                        <span>{data.sendout_method}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "116px" }}>Step 1</label>
                        <span>{data.sendout_step_1}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "116px" }}>Step 2</label>
                        <span>{data.sendout_step_2}</span>
                      </div>

                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "116px" }}>Step 3</label>
                        <span>{data.sendout_step_3}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label  style={{ minWidth: "116px" }}>Step 4</label>
                        <span>{data.sendout_step_4}</span>
                      </div> */}
                      {/* <div
                        className="dk-suMMformGroupCon"
                        style={{
                          minHeight: "117px",
                          borderBottom: "0",
                          display: "unset",
                        }}
                      >
                        <label
                          style={{
                            minWidth: "117px",
                            height: "150px",
                            borderRight: "1px solid #cecece",
                            float: "left",
                            display: "flex",
                            justifyContent: "left",

                          }}
                          className="dkg-sendout-label-234"
                        >
                          Other Req
                        </label>
                        <span
                          style={{
                            height: "30px",
                            borderBottom: "1px solid #cecece",
                            width: "100%",
                            display: "block",
                            padding: "4px 7px"
                          }}
                        >{data.sendout_email_1}</span>

                        <span
                          style={{
                            height: "30px",
                            borderBottom: "1px solid #cecece",
                            width: "100%",
                            display: "block",
                          }}
                        >{data.sendout_email_2}</span>

                        <span
                          style={{
                            height: "30px",
                            borderBottom: "1px solid #cecece",
                            width: "100%",
                            display: "block",
                          }}
                        >{data.sendout_email_3}</span>

                        <span
                          style={{
                            height: "30px",
                            borderBottom: "1px solid #cecece",
                            width: "100%",
                            display: "block",
                          }}
                        >{data.sendout_email_4}</span>

                        <span
                          style={{
                            height: "30px",
                            borderBottom: "1px solid #cecece",
                            width: "100%",
                            display: "block",
                          }}
                        >{data.sendout_email_5}</span>
                      </div> */}
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Language 1</label>
                        <span style={{ width: "145px" }}>{data.lang}</span>
                        <span style={{ width: "120px" }}>{data.lang_lvl}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Language 2</label>
                        <span style={{ width: "145px" }}>{data.lang2}</span>
                        <span style={{ width: "120px" }}>{data.lang2_lvl}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Your Location</label>
                        <span>{data.locality}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Relocation</label>
                        <span>{data.relocation}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Job Skills</label>
                        <span>{data.skills_set_req}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Tech Skills</label>
                        <span>{data.skills}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Qualification</label>
                        <span>{data.qualifying}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Certification</label>
                        <span>{data.certification}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Typing Speed</label>
                        <span>{data.typingSpeed}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Work Training</label>
                        <span>{data.experience_1}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Work Experience</label>
                        <span>{data.experience_2}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Online Assessment</label>
                        <span>{data.interviewMode_1}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Phone Interview</label>
                        <span>{data.interviewMode_2}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}>Video Interview</label>
                        <span>{data.interviewMode_3}</span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}></label>
                        <span></span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}></label>
                        <span></span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}></label>
                        <span></span>
                      </div>
                      <div className="dk-suMMformGroupCon">
                        <label style={{ minWidth: "140px" }}></label>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 pl-0 pr-0">
              <div
                className="col-md-12 pb-2 dk-suMMRightCon dk-jobsuMMBottomSec mt-0"
                style={{ paddingLeft: "0.55rem" }}
              >
                <div className="col-md-12 col-sm-12 dk-jobsuMMBottomBoxBg p-0">
                  <div className="dk-BoxHeader">
                    <h3>Relocation Support  <span className="dkg-pophover-icon"><BsInfo /></span></h3>
                  </div>
                  <div className="dk-boxKpi">
                    <div className="dk-suMMformGroupCon">
                      <label
                        className="relocationlabel"
                        style={{ minWidth: "175px" }}
                      >
                        Availability
                      </label>
                      <span>{data.availability}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label
                        className="relocationlabel"
                        style={{ minWidth: "175px" }}
                      >
                        Outside of EU
                      </label>
                      <span>{data.outside_of_eu}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label
                        className="relocationlabel"
                        style={{ minWidth: "175px" }}
                      >
                        Flight Ticket
                      </label>
                      <span>{data.flight_ticket}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label
                        className="relocationlabel"
                        style={{ minWidth: "175px" }}
                      >
                        Accommodation
                      </label>
                      <span>{data.accommodation}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label
                        className="relocationlabel"
                        style={{ minWidth: "175px" }}
                      >
                        Taxi from Airport
                      </label>
                      <span>{data.taxi_from_airport}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label
                        className="relocationlabel"
                        style={{ minWidth: "175px" }}
                      >
                        Client Fixed Budget
                      </label>
                      <span>{data.relocation_budget}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label
                        className="relocationlabel"
                        style={{ minWidth: "175px" }}
                      >
                      </label>
                      <span></span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label
                        className="relocationlabel"
                        style={{ minWidth: "175px" }}
                      >
                      </label>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-12 dk-suMMRightCon dk-jobsuMMBottomSec"
                style={{ paddingLeft: "0.55rem", marginTop: "17px" }}
              >
                {/* <div className="col-md-12 col-sm-12 dk-jobsuMMBottomBoxBg p-0">
                  <div className="dk-BoxHeader"> */}
                <div className="dk-suMMRightConBoxBg">
                  <div className="dk-suMMRightBoxHeader">
                    <h3>Hiring Process  <span className="dkg-pophover-icon"><BsInfo /></span></h3>
                  </div>
                  {/* <div className="dk-boxKpi"> */}
                  <div className="dk-suMMRightboxBody">
                    <div className="dk-suMMformGroupCon">
                      <label className="relocationlabel text-center" style={{ minWidth: "32px" }}>1</label>
                      <span>{data.hiringProcess_1}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label className="relocationlabel text-center" style={{ minWidth: "32px" }}>2</label>
                      <span>{data.hiringProcess_2}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label className="relocationlabel text-center" style={{ minWidth: "32px" }}>3</label>
                      <span>{data.hiringProcess_3}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label className="relocationlabel text-center" style={{ minWidth: "32px" }}>4</label>
                      <span>{data.hiringProcess_4}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label className="relocationlabel text-center" style={{ minWidth: "32px" }}>5</label>
                      <span>{data.hiringProcess_5}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label className="relocationlabel text-center" style={{ minWidth: "32px" }}>6</label>
                      <span>{data.hiringProcess_6}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label className="relocationlabel text-center" style={{ minWidth: "32px" }}>7</label>
                      <span>{data.hiringProcess_7}</span>
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label className="relocationlabel text-center" style={{ minWidth: "32px" }}>8</label>
                      <span>{data.hiringProcess_8}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
