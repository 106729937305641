import React from "react";

const ProjectTab = ({ pdata, title }) => {
  return (
    <>
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">{title}</div>
      </div>
      <div className="dkg-jobspec-read-editor-Con">
        <div className="dkg-jobspec-read-editor">
          <div
            className="dkg-jobspec-htmleditor"
            dangerouslySetInnerHTML={{
              __html: pdata,
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default ProjectTab;
