import React from "react";

const OurServices = ({ servicedetails, aboutusListData }) => {
  return (
    <>
      <div className="dkg-ourservices-mainCon">
        <div className="dkg-ourservices-secondRow">
          <div className="dkg-ourservice-header">
            <div className="dkg-ourservice-title">OUR CLIENTS SERVICES</div>
          </div>
          <div className="dkg-ourservice-body dkg-ourservice-new-bodyCon">
            <p className="dkg-service-desc dkg-ourservice-desc">
              {servicedetails != "" && servicedetails != null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: servicedetails.serviceClient,
                  }}
                ></div>
              ) : (
                ""
              )}
            </p>
            <div className="dkg-ourservice-tableCon">
              <table className="table dkg-ourservice-table table-bordered">
                <tbody>
                  {aboutusListData
                    .filter(
                      (item) =>
                        item.type === "Clients Services" &&
                        item.descriptions != ""
                    )
                    .map((data, index) => (
                      <tr key={index + 1}>
                        <td>{data.srNo}</td>
                        <td>{data.descriptions}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="dkg-services-middle-div"></div>
        <div className="dkg-ourservices-firstRow">
          <div className="dkg-ourservice-header">
            <div className="dkg-ourservice-title">OUR JOBSEEKER SERVICES</div>
          </div>
          <div className="dkg-ourservice-body dkg-ourservice-new-bodyCon">
            <p className="dkg-service-desc dkg-ourservice-desc">
              {servicedetails != "" && servicedetails != null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: servicedetails.serviceJobseekers,
                  }}
                ></div>
              ) : (
                ""
              )}
            </p>
            <div className="dkg-ourservice-tableCon">
              <table className="table dkg-ourservice-table table-bordered">
                <tbody>
                  {aboutusListData
                    .filter(
                      (item) =>
                        item.type === "Jobseekers Services" &&
                        item.descriptions != ""
                    )
                    .map((data, index) => (
                      <tr key={index + 1}>
                        <td>{data.srNo}</td>
                        <td>{data.descriptions}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices;
