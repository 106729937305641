import React, { useEffect, useLayoutEffect, useState, memo } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import FaqAccordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategory
} from "../../../../../../slice/faqs/newFaqSlice";

const Overview = ({ client_idd, jobId }) => {
  const dispatch = useDispatch();
  const {
    categoryList, iscat,
    isSuccess
  } = useSelector((state) => state.faqData);

  const [isCompanyName, isSetCompanyName] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(getCategory({ id: jobId }))
      } catch (err) {
        // dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch, isSuccess]);

  return (
    <>
      <div className="dkg-payment-faq-pageCon">
        <div className="row dkg-candprescreen-mainRow" style={{ backgroundColor: "transparent", border: "none" }}>
          {/* <div className="col-md-2 pl-0 dkg-cand-prescreen-faq-tabCon d-none">
            <Nav
              variant="pills"
              className="flex-column dkg-cand-prescreen-faq-tab-navpills"
            >
              {data.filter((item) => item.status === "Active")
                .map((comp, index) => {
                  return (
                    <Nav.Item
                      key={index}
                      className="dkg-cand-prescreen-faq-tab-nav-item cateNaam1"

                    >
                      <Link
                        to="#"
                        onClick={() =>
                          changeTabName(index, comp.id, comp.name)
                        }
                        className={
                          index === defaultActiveCompany
                            ? `dkg-cand-prescreen-faq-tab-navlink active`
                            : `dkg-cand-prescreen-faq-tab-navlink`
                        }
                      >
                        {comp.name}
                      </Link>
                    </Nav.Item>
                  );
                })}
            </Nav>
          </div> */}
          {/* <div className="col-md-10 dkg-cand-prescreen-centre-tabCon"> */}
          <div className="col-md-12 p-0 dkg-clientlist-faq-mainCon">
            <Tab.Container defaultActiveKey="question-pt-overview">
              <div className="row m-0 dkg-payment-faq-tab-mainCon">
                <div className="dkg-faq-catgory-activeTitle">
                  {isCompanyName}
                </div>
                <div className="col-md-2 pl-0 dkg-payment-faq-tabCon dkg-faq-sideMenuCon-234">
                  <Nav
                    variant="pills"
                    className="flex-column dkg-payment-faq-tab-navpills"
                  >
                    {categoryList.length > 0 ?
                      categoryList.filter((item) => item.status === "Active")
                        .map((cat, index) => {
                          return (
                            <>
                              <Nav.Item className="dkg-payment-faq-tab-nav-item">
                                {
                                  <Nav.Link eventKey={`first` + index} className={`dkg-payment-faq-tab-navlink`}>{cat.name}</Nav.Link>
                                }
                              </Nav.Item>
                            </>
                          );
                        })
                      :
                      null
                    }

                  </Nav>
                </div>
                <div className="col-md-10 dkg-payment-faq-tabCon-10 dkg-faq-rightMenuCon-234 pr-0">
                  {
                    <Tab.Content className="dkg-payment-faq-tabcontent ddd">
                      {categoryList.length > 0 ?
                        categoryList.filter((item) => item.status === "Active")
                          .map((cat, index) => {
                            return (
                              <Tab.Pane eventKey={`first` + index}>
                                <FaqAccordion faqdata={cat.quesAns} />
                              </Tab.Pane>
                            );
                          })
                        : null
                      }
                    </Tab.Content>
                  }

                </div>

              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
