import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess } from "../../../../../slice/utils/message/messageSlice";
import {
  getMyDocumentData,
  filesCategory,
  deletdocuments,
  clearState,
} from "../../../../../slice/cmsSlice";
import { Link } from "react-router-dom";
import { FaRegTrashAlt, FaDownload } from "react-icons/fa";
import "./documents.scss";
import AddNewModal from "./modal/envelope/AddNewModal";
import EditModal from "./modal/envelope/EditModal";
//import RenderTooltip from "./RenderTooltip";
import MoreInfoPopover from "./MoreInfoPopover";
//import moment from "moment-timezone";
import DocumentModal from "../documents/modal/docPreview";
import { SYSTEM_CONSTANTS } from "../../../../../constants";
import DeletePopup from "../../../../ui/Modal/deletemodal/DeleteModal";
import { getCMS } from "../../../../../slice/cmsSlice";

const Document = () => {
  const dispatch = useDispatch();
  const { mydocumentsList, isInsert, isLoading, isDelete, isSuccess, cmsList } =
    useSelector((state) => state.cmsdata);
  const { userInfo } = useSelector((state) => state.auth);
  const [documentData, setDocumentData] = useState([]);
  const [details, setDetails] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[30].description);
    }
  }, [isSuccess]);

  // const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      await dispatch(getMyDocumentData({ canId: userInfo.canId }));
      await dispatch(filesCategory({ id: SYSTEM_CONSTANTS.DOC_CATEGORY.ID }));
    };
    fetch();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && mydocumentsList.length > 0) {
      setDocumentData(mydocumentsList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInsert && !isLoading) {
      dispatch(clearState());
      dispatch(showSuccess({ msg: "Document uploaded successfully" }));
      dispatch(getMyDocumentData({ canId: userInfo.canId }));
    }
    if (isDelete) {
      dispatch(clearState());
      window.location.reload();
      //dispatch(showSuccess({ msg: "Document deleted successfully" }));
      //handleClose();
      //dispatch(getMyDocumentData({ canId: userInfo.canId }));
    }
  }, [isInsert, isDelete]);

  // const [show, setShow] = useState(true);
  // const [documentId, setDocument] = useState("");
  // const [filename, setfilename] = useState("");
  const [delpop, setdelpop] = useState(false);
  const [rowidd, setRowidd] = useState(0);

  // const opeendoc = (id, file) => {
  //   setDocument(id);
  //   setfilename(file);
  //   // setShow(true);
  // };

  const handleDownload = (fileUrl, fileName) => {
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileName;
    a.target = "_blank";
    a.click();
  };
  const opendelete = (id) => {
    setRowidd(id);
    setdelpop(true);
  };
  const handleClose = () => {
    setdelpop(false);
  };
  const deleteItem = () => {
    dispatch(deletdocuments({ id: rowidd }));
  };

  const [searchText, setSearchText] = useState("");
  const [crossSearchbtn, setCrossSearchbtn] = useState(false);

  const searchData = () => {
    setCrossSearchbtn(true);
    let query = searchText.toLowerCase();
    setDocumentData(
      mydocumentsList.filter((item) => {
        const fileCategoryMatch = item.fileCategory
          .toLowerCase()
          .includes(query);
        const onlyfileNameMatch = item.onlyfileName
          .toLowerCase()
          .includes(query);

        return fileCategoryMatch || onlyfileNameMatch;
      })
    );
  };

  const clearBtn = () => {
    setSearchText("");
    setCrossSearchbtn(false);
    setDocumentData(mydocumentsList);
  };

  // console.log(documentData);
  return (
    <>
      {/* {show ? (
        <DocumentModal file_name={filename} documentid={documentId} />
      ) : (
        ""
      )} */}
      {delpop ? (
        <DeletePopup handleClose={handleClose} deleteItem={deleteItem} />
      ) : null}

      <div className="dkg-documents-mainCon">
        <div className="dkg-documents-header">
          <div className="dkg-documents-headerleftCon">
            <div className="dk-mailshotsSearch mr-2">
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Search ..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {/* <div className="input-group-append">
                  <button className="btn btn-secondary" type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </div> */}
                {!crossSearchbtn ? (
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={searchData}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {crossSearchbtn ? (
                <div className="dkg-crossicon-Con">
                  <i
                    className="fas fa-times-circle"
                    data-type="search text"
                    onClick={clearBtn}
                  ></i>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <Link to="#" className="dkg-refresh-btn">
              <FaSyncAlt />
            </Link> */}
          </div>
          <div className="dkg-documents-centreCon">
            <div className="dkg-docs-title">
              {/* MY SAVED DOCUMENTS <RenderTooltip /> */}
              MY SAVED DOCUMENTS <MoreInfoPopover details={details} />
            </div>
          </div>
          <div className="dkg-documents-rightCon">
            {/* <Link to="#" className="dkg-refresh-btn mr-2">
              <FaArrowsAlt />
            </Link> */}
            <AddNewModal />
          </div>
        </div>
        <div className="dkg-document-tableCon table-responsive">
          <table className="dkg-document-table table table-bordered">
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th>File Category</th>
                <th>File Name</th>
                <th>File Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {documentData.length > 0 &&
                documentData.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.docDate}</td>
                    <td>{item.fileCategory}</td>
                    <td>{item.onlyfileName}</td>
                    <td>{item.fileType}</td>
                    <td>
                      <div className="dkg-documents-cols">
                        <DocumentModal
                          file_name={item.onlyfileName}
                          documentid={item.id}
                        />
                        {/* <Link to className="dkg-action-btn download-btn mr-2">
                          <FaDownload />
                        </Link> */}
                        <button
                          className="dkg-action-btn download-btn mr-2"
                          onClick={() =>
                            handleDownload(
                              process.env.REACT_APP_INTERNAL_BASE_URL +
                                item.fileName,
                              item.onlyfileName
                            )
                          }
                        >
                          <FaDownload />
                        </button>

                        <EditModal
                          imageUri={
                            process.env.REACT_APP_INTERNAL_BASE_URL +
                            item.fileName
                          }
                          addedDate={item.docDate}
                          filename={item.onlyfileName}
                          fileUrl={item.fileName}
                          rowid={item.id}
                          catNm={item.fileCategory}
                        />
                        {/*  */}
                        <Link
                          to
                          className="dkg-action-btn delete-btn"
                          onClick={(e) => opendelete(item.id)}
                        >
                          <FaRegTrashAlt />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Document;
