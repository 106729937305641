import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./jobsrightmodal.scss";
import { Modal, Tab, Nav } from "react-bootstrap";
import Summary from "./summary/Summary";
import SalaryTab from './salarynew/Salary'
import JobbenfitsTab from './salarynew/Jobbenfits'

import JobSummaryDetails from "./jobsummarydetails/JobSummary";
import Jobdetail from "./jobdetail/Jobdetail";
import CandidateView from "./candidates/Candidate";
// import Fulljobdetails from "./fulljobdetails/FullDetails";
// import Reacuiter from "../../../../../assets/images/deepak.png";
// import Specs from "./specs/Specs";
// import AdPlan from "./ad_plan/AdPlan";
// import Adsample from "./adsample/Adsample";
// import Resources from "./resources/Resources";
// import Interviews from "./interviews/Interviews";
// import Salary from "./salary/Salary";
// import Benefits from "./benefits/Benefits";
// import Relocation from "./relocation/Relocation";
// import Company from "./company/Company";
// import JobFile from './jobfiles/JobFile'
// import moment from "moment-timezone";
import {
  jobsDetails
} from "../../../../../slice/jobs/jobsSlice";

import {
  //getProjectSubCategories,
  //getCategories,
  getproject,
  getroletype
  //getdefaultSubCategories
} from "../../../../../slice/clientProject/clientprojectSlice";
// import {
//   getRoleSubCategories
// } from "../../../../../slice/clientRoleType";

import {
  getCMS
} from "../../../../../slice/cmsSlice";

import { projectcomp, allJobsSpecCompnay, defaultJobsCompnay, defaultJobsProject, allclientsproject, defaultJobsRole } from "../../../../../slice/jobSpec/jobSpecSlice";

import { fetechById } from "../../../../../slice/companies/clients/clientSlice";
import { getCategory, getOnClickQuesAns } from "../../../../../slice/faqs/newFaqSlice";
// import ClientProjects from "../../../companies/listview/modal/liveJobTab/projects/ClientProjects";
// import ClientRoletype from "../../../companies/listview/modal/liveJobTab/roletype/ClientRoletype";
// import ClientLanguage from "../../../companies/listview/modal/liveJobTab/language/ClientLanguage";
// import ClientCompany from '../../../companies/listview/modal/liveJobTab/company/ClientCompany';
import ClientCompany from './companies/DkgClientCompaines';
import DkgClientProject from './companies/DkgClientProject'

import ClientInterview from '../../../companies/listview/modal/liveJobTab/interviews/ClientInterviews';
// import ClientJoboffers from '../../../companies/listview/modal/liveJobTab/joboffers/ClientJobOffer';
// import ClientRelocation from '../../../companies/listview/modal/liveJobTab/relocation/ClientRelocaton';
// import ClinetsJoinning from '../../../companies/listview/modal/liveJobTab/joinning/ClientJoinnings';
import FaqOveriew from '../../../companies/listview/modal/faq/Overview';
//import { getdefaultCategory, getdefaultQuesAns } from "../../../../../slice/faqs/newFaqSlice";

import DkgRoletype from '../../../companies/listview/modal/liveJobTab/company/Roletype';

const DetialViewPoup = ({ show, onHide, jobId, popupfor, clientidds, canjobs, companyName }) => {

  const dispatch = useDispatch();
  const { isDetailsLoading, jobDetails } = useSelector((state) => state.jobs);
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  const { companyDetails } = useSelector((state) => state.companies);
  const {
    categoryList,
    //questionAnsList,
    iscat,
    //isQuesAns
  } = useSelector((state) => state.faqData);

  const [Jobdata, setJobdata] = useState("");
  const [JobInstuctiondata, setJobInstuctiondata] = useState("");
  const [faqcat, setFaqcat] = useState([])

  useEffect(() => {
    if (show) {
      const fetchRecods = async () => {
        await dispatch(jobsDetails({ id: jobId }));
        await dispatch(getCMS({}));
        //dispatch(getCategory({ id: companyDetails.clientId }))
        // await dispatch(getdefaultCategory({ compid: jobId }));
        // await dispatch(getdefaultQuesAns({ compid: jobId }));
      };
      fetchRecods();
    }
  }, [show]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setJobdata(jobDetails);
    }
    if (!isSuccess) {
      setJobInstuctiondata(cmsList);
    }
    if (!iscat) {
      setFaqcat(categoryList);
    }
  }, [isDetailsLoading, isSuccess, iscat]);

  useEffect(() => {
    dispatch(projectcomp({ jobId: canjobs }));
    dispatch(fetechById({ id: clientidds }))
  }, [canjobs]);

  const [eventKey, SetEventKey] = useState("jobSummaryOverview");
  const [faqTab, setFaqTab] = useState(false);

  const callFaqBtn = () => {
    dispatch(getCategory({ id: companyDetails.clientId }))
  }
  const [changeTabKey, SetChangeTabKey] = useState("full-job-details");

  useEffect(() => {
    if (changeTabKey) {
      switch (changeTabKey) {
        case "full-job-details":
          //console.log("job deails");
          dispatch(allJobsSpecCompnay({}))
          dispatch(getproject({ jobId: Jobdata.job_id, clientId: clientidds }));
          dispatch(getroletype({ jobId: Jobdata.job_id, clientId: clientidds }));
          dispatch(getCategory({ id: Jobdata.job_id }))
          break;
        case "edit-jobdetails":
          //console.log("Edit job deails");
          dispatch(defaultJobsCompnay({ jobsId: Jobdata.job_id, tbl: "clientinfo_requirnment_categories" }));
          dispatch(allJobsSpecCompnay({}))//data come from master table
          //project table
          dispatch(getproject({ jobId: Jobdata.job_id, clientId: clientidds }));
          dispatch(defaultJobsProject({ jobsId: Jobdata.job_id, tbl: "client_project_categories" }));
          dispatch(allclientsproject({}))//data come from master table

          dispatch(defaultJobsRole({ jobsId: Jobdata.job_id, tbl: "client_roletype_categories" }));
          dispatch(getroletype({ jobId: Jobdata.job_id, clientId: clientidds }));
          break;
        default:
          console.log("default");
          break;

      }
    }
  }, [changeTabKey])

  useEffect(() => {
    if (eventKey) {
      //console.log(canjobs)
      dispatch(projectcomp({ jobId: canjobs }));
      setFaqcat([])
      switch (eventKey) {
        case "jobSummaryOverview":
          console.log("jobSummaryOverview")
          break;
        case "company":
          console.log("company tab")
          dispatch(allJobsSpecCompnay({}));
          //dispatch(getSubCategories({ categoryId: 1, keys: "Overview" }));
          break;
        case "jobs-project":
          dispatch(getproject({ jobId: Jobdata.job_id, clientId: clientidds }));
          break;
        case "job-new-role":
          console.log("role tab")
          dispatch(getroletype({ jobId: Jobdata.job_id, clientId: clientidds }));
          break;
        case "dkg-interves":
          console.log("interview tab")
          break;
        case "job-offerss":
          console.log("job offers tab")
          break;
        case "dkg-new-relocation":
          console.log("relocaion tab")
          break;
        case "job-whyapply":
          console.log("joblanguage tab")
          break;
        case "live-jobs-faq":
          dispatch(getCategory({ id: Jobdata.job_id }))
          break;

      }
    }
  }, [eventKey]);

  return (
    <React.Fragment>
      <div className="dk-cliDetViewPopup">
        <Modal
          show={show}
          dialogClassName="dk-livejobrightModalDailog"
          aria-labelledby="example-custom-modal-styling-title"
          onHide={onHide}
        >
          <Modal.Header closeButton className="dk-detViewheader">
            <Modal.Title
              className="dk-detViewTitle"
              id="example-custom-modal-styling-title"
            >
              <div className="modalTitleCon">JOB ID - {Jobdata.job_id} </div>
              <div
                className="modalTitleCon"
              // data-id={Jobdata.id}
              // data-column="updated_on"
              // onClick={onDateClick}
              >
                {/* Full Job Details */}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="dkg-full-jobdetail-modalmody dkg-edit-full-jobdetail-modalmody">
            <div className="dkg-full-jobdetail-maincon dkg-full-edit-jobdetail-maincon">
              <Tab.Container id="left-tabs-example" defaultActiveKey="full-job-details">
                <div className="dkg-full-jobdetail-mainRow dkg-full-jobdetail-mainRow row">
                  <div className="dkg-full-jobdetail-mainCol dkg-full-edit-jobdetail-mainCol col-9 m-auto">
                    <Nav variant="pills" onSelect={(selectedKey) => SetChangeTabKey(selectedKey)} className="flex-row dkg-full-jobdetail-navpills dkg-full-edit-jobdetail-navpills">
                      <Nav.Item className="dkg-full-jobdetail-navitem">
                        <Nav.Link eventKey="full-job-details" className="dkg-full-jobdetail-navlink">{Jobdata.name}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="dkg-full-jobdetail-navitem">
                        <Nav.Link eventKey="edit-jobdetails" className="dkg-full-jobdetail-navlink">Edit</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="dkg-full-jobdetail-mainCol-12 dkg-full-edit-jobdetail-mainCol-12 col-12">
                    <Tab.Content className="dkg-full-jobdetail-tabcontent dkg-full-edit-jobdetail-tabcontent">
                      <Tab.Pane eventKey="full-job-details" className="dkg-full-jobdetail-tabpane dkg-full-edit-jobdetail-tabpane">
                        <div className="dk-LivejobPopCon dk-edit-LivejobPopCon">
                          <div className="dk-clientDetViewTopSec dk-edit-clientDetViewTopSec">
                            <div className="row mr-0 ml-0">
                              <div className="col-md-12 col-sm-12 dk-edit-DetViewBottomSecCon dk-DetViewBottomSecCon pl-0 pr-0">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="jobSummaryOverview"
                                >
                                  <div className="row mr-0 ml-0">
                                    <div
                                      className="col-md-12 col-sm-12 dk-ClieDetViewSideMenu pl-0 pr-0"
                                      id="dk-cliDetailsV"
                                    >
                                      <Nav variant="pills" className="flex-row dkg-detailview-navpills dkg-edit-detailview-navpills" onSelect={(selectedKey) => SetEventKey(selectedKey)} >
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="jobSummaryOverview">Instructions</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="details">Overview</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="dkg-livejob-salary">Salary</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="company">Company</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="jobs-project">Project</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="job-new-role">Role Type</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="dkg-interves">Interviews</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="job-offerss">Job Offer</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="dkg-new-relocation">Relocation</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="dkg-joinning">Joining</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="job-whyapply">Why Apply</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem" onClick={callFaqBtn}>
                                          <Nav.Link eventKey="live-jobs-faq">FAQ</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-12 col-sm-12 dk-ClieDetViewMainCont ttt dkg-jobspecpopup-col-12">
                                      <Tab.Content className="dkg-jobspecpopup-tabContent">
                                        <Tab.Pane eventKey="jobSummaryDetails" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane jobSummaryDetails dkg-livejobs-suMMTabpane pl-0 pr-0">
                                            <JobSummaryDetails jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="jobSummaryOverview" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                            <div className="instructionTabs">

                                              {
                                                JobInstuctiondata != "" && JobInstuctiondata != null ? <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: JobInstuctiondata[0].description,
                                                  }}
                                                ></div>
                                                  :
                                                  ''
                                              }

                                            </div>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="details" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                            <Summary jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-livejob-salary" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                            <SalaryTab jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="job-offerss" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane summaryTabPane">
                                            <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="company" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane  dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <ClientCompany client_idd={clientidds} jobId={Jobdata.job_id} />
                                          </div>
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="salary">
                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                <Salary jobId={jobId} />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="benefits">
                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                <Benefits jobId={jobId} />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="relocation">
                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                <Relocation jobId={jobId} />
                                            </div>
                                        </Tab.Pane> */}

                                        <Tab.Pane eventKey="jobs-project" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="job-new-role" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            {/* <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} /> */}
                                            <DkgRoletype client_idd={clientidds} jobId={Jobdata.job_id} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="job-whyapply" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="job-new-benfits" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                            <JobbenfitsTab jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="job-new-benfits">
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78">
                                            <ClientSendouts />
                                          </div>
                                        </Tab.Pane> */}
                                        <Tab.Pane eventKey="interviews" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <ClientInterview client_idd={clientidds} jobId={Jobdata.job_id} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="candidate" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane dkg-likedislike-tabpane">
                                            <CandidateView jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-interves" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} />
                                          </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="dkg-new-relocation" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} />
                                          </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="dkg-joinning" className="dkg-jobspecpopup-tabpane">
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="live-jobs-faq" className="dkg-livejobs-faq-tabpane">
                                          <FaqOveriew client_idd={clientidds} jobId={Jobdata.job_id} />
                                        </Tab.Pane>
                                        {/* // <Tab.Pane eventKey="live-jobs-faq" className="dkg-livejobs-faq=tabpane">
                                        //   <FaqOveriew jobId={Jobdata.job_id} />
                                        // </Tab.Pane> */}

                                        {/* <Tab.Pane eventKey="workflow">
                                              <div className="dk-ClentViewTabPane">
                                                  <Resources jobId={jobId} />
                                              </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="ad-sample">
                                              <div className="dk-ClentViewTabPane">
                                                  <Adsample jobId={jobId} />
                                              </div>
                                          </Tab.Pane> */}
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="edit-jobdetails" className="dkg-full-jobdetail-tabpane">
                        <Jobdetail jobId={jobId} canjobs={canjobs} company_name={companyName} clientid={clientidds} />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DetialViewPoup;
