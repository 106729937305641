import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Nav, Dropdown } from "react-bootstrap";

import ProjectTab from "./ProjectTab";

const DkgClientCompaines = ({ client_idd, jobId }) => {
  //const dispatch = useDispatch();
  const { activeprojecList, isProjectLoading } = useSelector(
    (state) => state.clientproject
  );

  const [eventKey, SetEventKey] = useState("jobpsec-company");
  useEffect(() => {
    if (eventKey) {
      switch (eventKey) {
        case "jobpsec-company":
          //console.log("job deails");
          break;
        case "jobpsec-company":
          //console.log("job deails");
          break;
        default:
          console.log("default");
          break;
      }
    }
  }, [eventKey]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isProjectLoading) {
      setData(activeprojecList.projectdetails);
    }
  }, [isProjectLoading]);

  return (
    <>
      <div
        className="dkg-jobspec-company-mainCon dkg-jobspec-modal-company-mainCon"
        style={{ marginTop: "20px", height: "calc(100vh - 92px)" }}
      >
        <div className="dkg-jobspec-company-header">
          <div className="dkg-jobpsec-DropdownCon">
            <div className="dkg-jobpsec-page-title">
              {data ? activeprojecList.companyName : ""}
            </div>
          </div>
        </div>

        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="jobpsec-company0"
        >
          <div className="row dkg-jobspec-company-mainRow">
            <div className="col-2 dkg-jobspec-company-sideMenu">
              <Nav
                variant=""
                className="dkg-jobspec-company-navpills flex-column"
                onSelect={(selectedKey) => SetEventKey(selectedKey)}
              >
                <Nav.Item className="dkg-jobspec-company-navitem">
                  {data &&
                    data.map((item, index) => (
                      <Nav.Link
                        eventKey={`jobpsec-company` + index}
                        className="dkg-jobspec-company-navlink"
                      >
                        {item.subCategoryName}
                      </Nav.Link>
                    ))}
                </Nav.Item>
              </Nav>
            </div>
            <div className="dkg-jobspec-rightCon col-md-10">
              <Tab.Content className="dkg-jobspec-tabcontent">
                {data &&
                  data.map((item, index) => (
                    <Tab.Pane
                      eventKey={`jobpsec-company` + index}
                      className="dkg-jobspec-tabpane"
                    >
                      <ProjectTab
                        pdata={item.description}
                        title={item.subCategoryName}
                      />
                    </Tab.Pane>
                  ))}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default DkgClientCompaines;
